import React from "react";
import { Col, Row } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import brandData from "../../data/brand/brand.json";

const BrandSlider = (props) => {
  const params = {
    spaceBetween: 30,
    slidesPerView: 4,
    loop: true,
    autoplay: {
      delay: 1000,
      disableOnInteraction: false,
    },
    navigation: false,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },

      768: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 4,
      },
      1500: {
        slidesPerView: 4,
      },
    },
  };

  return (
    <div id="client-section" className="client-section  pb-0 clearfix">
      <div id="client-carousel" className="client-carousel">
        <div className="container">
          <Row>
            {/* <!-- client-section - start
		================================================== --> */}

            {brandData.map(function (slide, index) {
              return (
                <Col xs={12} sm={6} md={6} lg={3} style={{display:'flex', justifyContent:'center'}}>
                  <img key={index}  src={slide.img} alt={slide.alt} className="p-5" style={{alignSelf:'center'}}/>
                </Col>
              );
            })}

            {/* <!-- client-section - end
		================================================== --> */}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default BrandSlider;
