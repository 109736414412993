import React from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { Button, Col, Row } from "reactstrap";

class ContactForm extends React.Component {
  render() {
    let {
      handleSubmit,
      handleChange,
      isSubmitting,
      values,
      errors,
    } = this.props;
    return (
      <div className="contact-form">
        <form onSubmit={handleSubmit} method="post" id="contact-form">
          <input type="hidden" name="contact_number"/>
          <Row>
            <Col xs={12} sm={6}>
              <div className="form-item">
                <input
                  type="text"
                  name="user_name"
                  placeholder="your name*"
                  value={values.user_name}
                  onChange={handleChange}
                />
                {errors.user_name && <p className="text-danger">{errors.user_name}</p>}
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className="form-item">
                <input
                  type="email"
                  name="user_email"
                  placeholder="email address*"
                  value={values.user_email}
                  onChange={handleChange}
                />
                {errors.user_email && <p className="text-danger">{errors.user_email}</p>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="form-textarea">
                <textarea
                  name="message"
                  placeholder="Your message here..."
                  onChange={handleChange}
                ></textarea>
                {errors.message && (
                  <p className="text-danger">{errors.message}</p>
                )}
              </div>
              <div className="text-center">
                <Button type="submit" style={{backgroundColor:'#6c9807', borderColor:'#6c9807'}}  name="submit">
                  {isSubmitting ? "Submitting... check console" : "send message"}
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

const AppalForm = withFormik({
  mapPropsToValues: (props) => props.values,
  validationSchema: Yup.object().shape({
    user_name: Yup.string().required("Name is required"),
    message: Yup.string().required("messages is required"),
    user_email: Yup.string()
      .email("Please enter valid email")
      .required("Email is required"),
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    props.handleSubmit(values, setSubmitting);
  },
  displayName: "BaseRateForm",
})(ContactForm);

export default AppalForm;
