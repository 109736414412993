import React, { useEffect, useState } from "react";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Input,
  Button,
} from "reactstrap";


import classnames from "classnames";
import TitleSection from "../Titlesection/TitleSection";
import titleTwo from "../../data/title/title-two.json";


const [{ title, Paragraph }] = titleTwo;

// TODO: pass in URL from props (and get it from configuration + build target)
const FeaturesNav = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <section
        id="extra-features-section"
        className="extra-features-section sec-ptb-160 bg-light-gray clearfix"
        style={{backgroundColor: '#F7FCF8'}}
      >
        <Container>
          <Row className="justify-content-center">
            <Col sm="12" lg="6" md="8">
              {
                <TitleSection spacer="mb-100" textAlign="text-center">
                  <h2 className="title-text mb-30">Highly Accurate Calculations</h2>
                  <p className="paragraph-text mb-0">This comprehensive evaluation has allowances for Scope 1,2 and 3 emissions.<br/>No other company provides this level of robust data-driven calculation.</p>
                </TitleSection>
              }
            </Col>
          </Row>

          <div className="features-nav ul-li-center">
            {/* class="nav" */}
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  <span className="item-icon">
                    <img
                      src="assets/images/home/calculator/scope-1.svg"
                      alt="icon_not_found"
                    />
                  </span>
                  Scope 1<br/>
                  <small>Direct emmisions from operations</small>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  <span className="item-icon">
                    <img
                      src="assets/images/home/calculator/scope-2.svg"
                      alt="icon_not_found"
                    />
                  </span>
                  Scope 2<br/>
                  <small>Indirect emmisions from purchased energy</small>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  <span className="item-icon">
                    <img
                      src="assets/images/home/calculator/scope-3.svg"
                      alt="icon_not_found"
                    />
                  </span>
                  Scope 3<br/>
                  <small>All other emissions associated with a company’s activities</small>
                </NavLink>
              </NavItem>
            </Nav>
          </div>         
        </Container>
      </section>
    </div>
  );
};

export default FeaturesNav;
