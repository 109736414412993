import React from "react";
import { Container, Row, Col } from "reactstrap";
import titleSeven from "../../data/title/title-seven.json";

const [{ title, paragraph, subTitle }] = titleSeven;
const FaqSection = (props) => {
  return (
    <section
      id="faq-section"
      className="faq-section clearfix"
    >
      <Container>
        <Row className="justify-content-center">
          <Col lg="7" md="8">
            <div
              className="section-title text-center"
              data-aos="fade-up"
              data-aos-duration="450"
              data-aos-delay="100"
            >
              <h2 className="title-text mb-100">Frequently Asked Questions</h2>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-lg-start justify-content-md-center">
          <Col lg="6" md="8">
            <div className="queistions-container mb-60">
              <div
                className="queistions-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="450"
                data-aos-delay="200"
              >
                <h3 className="item-title mb-15">
                  Who “owns” the carbon offset purchase?
                </h3>
                <p className="mb-0">
                  VacayGreen purchases and retires “on behalf of” our clients. Each purchaser receives a certificate from VacayGreen that can be shared across all major social media platforms.
                </p>
              </div>

              <div
                className="queistions-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="450"
                data-aos-delay="300"
              >
                <h3 className="item-title mb-15">
                  Are carbon offset and renewable energy certificates the same thing? 
                </h3>
                <p className="mb-0">
                No. Renewable Energy Certificates (RECs) and carbon offsets are both instruments that represent a quantifiable environmental benefit associated with an activity. 
A REC represents the environmental attributes derived from 1 megawatt-hour of renewable energy generation. 
A carbon offset represents the prevention or sequestration of 1 metric ton of carbon dioxide-equivalent emissions. 


                </p>
              </div>
              <div
                className="queistions-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="450"
                data-aos-delay="400"
              >
                <h3 className="item-title mb-15">
                Do carbon offsets really make a difference? 


                </h3>
                <p className="mb-0">
                Yes! But not all carbon offsets are created equal and that is why VacayGreen only purchases offsets that are certified to the highest standard. Projects associated with these carbon offsets create meaningful reductions in greenhouse gas emissions. We provide greenhouse gas equivalencies to display the benefit in concrete terms that are easy to visualize.
                </p>
              </div>
             
            </div>
          </Col>
          <Col lg="6" md="8">
            <div className="queistions-container mb-60">
              <div
                className="queistions-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="450"
                data-aos-delay="300"
              >
                <h3 className="item-title mb-15">
                  How does the purchaser know what has been paid for is actually purchased?
                </h3>
                <p className="mb-0">
                Every offset purchase is third-party verified via CAR, VCS, ACR or GS.  This ensures that each project meets our stringent standards at the project level, when and where the offsets are being created.  
Additionally, VacayGreen’s purchases are third-party audited annually.

                </p>
              </div>
              <div
                className="queistions-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="450"
                data-aos-delay="400"
              >
                <h3 className="item-title mb-15">
                  Can you deduct the purchase of carbon offsets from your income taxes?
                </h3>
                <p className="mb-0">
                  Carbon offset purchases can not be used as a tax deduction for individuals. 
                </p>
              </div>

              <div
                className="queistions-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="450"
                data-aos-delay="400"
              >
                <h3 className="item-title mb-15">
                Why are carbon offsets important for the vacation rental industry? 

                </h3>
                <p className="mb-0">
                The vacation rental industry by nature is very carbon footprint intensive. It is important we recognize this environmental impact and do our part to vacation responsibly. A great way to do this is by staying at carbon neutral properties!  

                </p>
              </div>
              
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col lg="4" md="6">
            <div
              className="contact-info text-center"
              data-aos="fade-up"
              data-aos-duration="450"
              data-aos-delay="500"
            >
              <p className="mb-0">
                Can’t find an answer? Drop us a line at
                <a href="mailto:info@vacaygreen.com">info@vacaygreen.com</a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FaqSection;
