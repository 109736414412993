import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const Navigation = ({ activeClass }) => {
  return (
    <Fragment>
      <nav className="main-menubar ul-li-right clearfix">
        <ul className="clearfix">
          <li
            className={`${
              activeClass ? activeClass : null
            }`}
          >
            <Link to="/more-info">Carbon Offsets</Link>
          </li>
          <li
            className={`${
              activeClass ? activeClass : null
            }`}
          >
            <Link to="/about-us">About Us</Link>
          </li>
          <li
            className={`${
              activeClass ? activeClass : null
            }`}
          >
            <a href="/#contact-section">Contact Us</a>
          </li>
        </ul>
      </nav>
    </Fragment>
  );
};

export default Navigation;
