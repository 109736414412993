import React, { useRef, useEffect } from "react";

import { Container, Row, Col, Button } from "reactstrap";
import Parallax from "parallax-js";
const BannerOne = (props) => {
  /*const scenElement = useRef(null);

  useEffect(() => {
    const parallaxInstance = new Parallax(scenElement.current, {
      relativeInput: true,
    });
    parallaxInstance.enable();
    return () => parallaxInstance.disable();
  }, []);*/

  return (
    <div style={{ backgroundImage:'url("/assets/images/home/Hero.svg")', backgroundRepeat:'no-repeat', backgroundSize:'contain', backgroundColor: '#F7FCF8', backgroundPosition:'bottom'}}>
      <section id="banner-section" className="banner-section clearfix" >
        <div className="mobile-app-banner-1">
          <Container>
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="12" className="text-center mb-100">
                
                <div className="banner-content">
                  <h2 style={{color:'#6C9807', fontSize:'6vw', width:'100%', fontFamily:"ProximaNova"}}>
                   Stay Responsibly
                  </h2>
                  <p className="mb-5">
                  <img
                    src={
                       "assets/images/home/vg-banner-dark.svg"
                    }
                    alt="logo_not_found"
                    style={{height:100, objectFit:'contain', marginRight:5}}
                    className="mt-3"
                   />
                  </p>
                  <p>
                    <Button style={{backgroundColor:'#6c9807', borderColor:'#6c9807'}} onClick={()=>{
                      window.location.href="/more-info";
                    }}>Learn more</Button>
                  </p>
                </div>
              </Col>
              {/** 
              <Col lg="5" md="8">
                <div
                  className="banner-image scene"
                  id="scene"
                  ref={scenElement}
                >
                  <span className="bg-image" data-depth="0.2">
                    <small
                      data-aos="zoom-in"
                      data-aos-duration="450"
                      data-aos-delay="100"
                    >
                      <img
                        src="assets/images/banner/mab-1-img-1.png"
                        alt="image_not_found"
                      />
                    </small>
                  </span>
                  <span className="phone-image" data-depth="0.2">
                    <small
                      data-aos="zoom-out"
                      data-aos-duration="500"
                      data-aos-delay="500"
                    >
                      <img
                        src="assets/images/banner/mab-1-img-2.png"
                        alt="image_not_found"
                      />
                    </small>
                  </span>
                  <span className="man-image-1" data-depth="0.5">
                    <small
                      data-aos="fade-right"
                      data-aos-duration="550"
                      data-aos-delay="800"
                    >
                      <img
                        src="assets/images/banner/mab-1-img-3.png"
                        alt="image_not_found"
                      />
                    </small>
                  </span>
                  <span className="man-image-2" data-depth="0.5">
                    <small
                      data-aos="fade-left"
                      data-aos-duration="600"
                      data-aos-delay="1000"
                    >
                      <img
                        src="assets/images/banner/mab-1-img-4.png"
                        alt="image_not_found"
                      />
                    </small>
                  </span>
                  <span className="man-image-3" data-depth="0.3">
                    <small
                      data-aos="fade-down"
                      data-aos-duration="650"
                      data-aos-delay="1200"
                    >
                      <img
                        src="assets/images/banner/mab-1-img-5.png"
                        alt="image_not_found"
                      />
                    </small>
                  </span>
                  <span className="cog-image" data-depth="0.4">
                    <small
                      data-aos="zoom-in"
                      data-aos-duration="1000"
                      data-aos-delay="1500"
                    >
                      <img
                        src="assets/images/banner/mab-1-img-6.png"
                        alt="image_not_found"
                      />
                    </small>
                  </span>
                  <span className="leaf-image-1" data-depth="0.4">
                    <small
                      data-aos="fade-left"
                      data-aos-duration="450"
                      data-aos-delay="1500"
                    >
                      <img
                        src="assets/images/banner/mab-1-img-7.png"
                        alt="image_not_found"
                      />
                    </small>
                  </span>
                  <span className="leaf-image-2" data-depth="0.4">
                    <small
                      data-aos="fade-right"
                      data-aos-duration="450"
                      data-aos-delay="1500"
                    >
                      <img
                        src="assets/images/banner/mab-1-img-8.png"
                        alt="image_not_found"
                      />
                    </small>
                  </span>
                </div>
  </Col>*/}
            </Row>
          </Container>
        </div>
      </section>
    </div>
  );
};

export default BannerOne;
