import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie'
import { Button, Col, Input, Row } from 'reactstrap'
import * as animationData from '../Calculator/lottie/calculator.json'
import { getEmissionsEstimate } from "../../services/EstimateService";

const Calculator = () => {

  const [stopped, setStopped] = useState(true);
  const [paused, setPaused] = useState(false);

  const [validPropertyCount, setValidPropertyCount] = useState("");
  const [hasPropertyCountError, setHasPropertyCountError] = useState(true);
  const [validZipCode, setValidZipCode] = useState("");
  const [hasZipCodeError, setHasZipCodeError] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isRequested, setIsRequested] = useState(false);

  const [animation, setAnimation] = useState(animationData.default);

  const defaultOptions = {
    loop: false,
    autoplay: false, 
    animationData: animation,
  };

  const errorMessages = {
    propertyCount: "Enter a number between 1 and 1000.",
    zipCode: "Enter a valid 5-digit zip code.",
  };

  const validatePropertyCount = (e) => {
    const value = e.target.value;
    let validInput = true;
    const parsedIntValue = parseInt(value);
    if(isNaN(parsedIntValue)) {
      validInput = false;
    }
    else if(parsedIntValue < 1 || parsedIntValue > 1000) {
      validInput = false;
    }

    setHasPropertyCountError(!validInput);
    if(validInput) {
      setValidPropertyCount(parsedIntValue);
    } else {
      // Don't set ValidPropertyCount to NaN, but still display the user's input.
      setValidPropertyCount(value);
    }
  }

  const validateZipCode = (e) => {
    const value = e.target.value;
    let validInput = true;
    const parsedIntValue = parseInt(value);
    if(isNaN(parsedIntValue)) {
      validInput = false;
    }
    else if(value.length !== 5) {
      validInput = false;
    }

    setHasZipCodeError(!validInput);
    if(validInput) {
      setValidZipCode(parsedIntValue);
    }
    else {
      // Don't set ValidZipCode to NaN, but still display the user's input.
      setValidZipCode(value);
    }
  }

  const resetForm = () => {   
    setIsSubmitted(false);
    setIsRequested(false);
    setValidPropertyCount("");
    setHasPropertyCountError(true);
    setValidZipCode("");
    setHasZipCodeError(true);

    setStopped(true)
  }

  async function runCalculation() {
    setIsSubmitted(true);
    if(hasPropertyCountError || hasZipCodeError) {
      return;
    }
    setIsRequested(true);
    await getEmissionsEstimate(validZipCode, validPropertyCount)
      .then(estimate => { 
        if (estimate.hasError) {
          setHasZipCodeError(true);
          return;
        }

        var messageLine1 = estimate.result.forestAcresCount.toLocaleString();
        var messageLine3 = "equivalent to " + estimate.result.cityBlocksCount.toLocaleString() + " City Blocks"
        animation.done = true;
        animation.layers[3].t.d.k[0].s.t = messageLine1;
        animation.layers[1].t.d.k[0].s.t = messageLine3;
        setAnimation({...animation});
      });
  }

  useEffect(()=>{
    if(!animation.done) {
      return;
    }

    setStopped(false);
  },[animation])

  return (
    <div>
         <Row className="justify-content-lg-start justify-content-md-center m-3" style={{ backgroundColor: 'white', padding:'30px 20px', borderRadius:15, border:'2px solid #6C9807', boxShadow:'1px 1px 26px -7px #959ca8'}}>
            <Col sm="12" md="4">
              <div className="feature-content">
                <h3 className="item-title mb-2">
                  Property Managers
                </h3>
                <p>Estimate your potential environmental impact</p>
                <p className="mb-30">
                  <Input className="input" name="propertyCount" style={{border:'1px solid #6C9807'}} onChange={(e) => validatePropertyCount(e)} placeholder="Number of Properties" value={validPropertyCount} />
                  { (isSubmitted && hasPropertyCountError) ? <span className="text-danger">{errorMessages.propertyCount}</span> : null }
                </p>
                <p className="mb-30">
                  <Input className="input" name="zipCode" style={{border:'1px solid #6C9807'}} onChange={(e) => validateZipCode(e)} placeholder="Zip Code" value={validZipCode} />
                  { (isSubmitted && hasZipCodeError) ? <span className="text-danger">{errorMessages.zipCode}</span> : null }
                </p>
                <p>
                  <Button style={{backgroundColor:'#6c9807', borderColor:'#6c9807'}} disabled={isRequested} onClick={runCalculation}>Calculate</Button>
                  <Button className="ml-2" style={{backgroundColor:'white', borderColor:'#6c9807', color:'#6c9807'}} onClick={()=>{resetForm()}}>Reset</Button>
                </p>
              </div>
            </Col>
            <Col sm="12" md="8">
              <div className="feature-image text-center">
              <Lottie options={defaultOptions}
              
              isStopped={stopped}
              isPaused={paused}/>
              </div>
            </Col>
          </Row>
    </div>
  )
}

export default Calculator