import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import featureDataOne from "../../data/feature/feature-one.json";
import featureDataTwo from "../../data/feature/feature-two.json";
const [{ paragraph1, paragraph2, subTitle, title, span }] = featureDataOne;
const featureThree = ({ sectionSpace }) => {
  return (
    <Fragment>
      <section
        id="features-section"
        className={`features-section ${sectionSpace} clearfix`}
        style={{backgroundColor: '#F7FCF8'}}
      >
        <Container className="p-5">
          <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center mb-5">
              <Col lg="5" md="5" xs={12}>
                <div className="feature-content">
                  <div  className="section-title mb-200">
                    <p className="mb-0" style={{fontSize:'1.5rem', color:'black'}}>
                      <span style={{fontFamily:'ProximaNova-Bold'}}>Carbon offsets</span> refers to a reduction in Greenhouse Gas (GHG) emissions that compensate for emissions that occur elsewhere. 
                    </p>
                  </div>
                </div>
              </Col>

              <Col lg="6" md="6" xs={12}>
                <div className="sun-energy">
                  <span
                    className="background"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <img
                      src="assets/images/home/sun-energy/Vector-1.svg"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="house"
                    data-aos="fade-right"
                    data-aos-delay="400"
                  >
                    <img
                      src="assets/images/home/sun-energy/House.svg"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="floor"
                    data-aos="fade-right"
                    data-aos-delay="400"
                  >
                    <img
                      src="assets/images/home/sun-energy/Floor.svg"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="trees"
                    data-aos="fade-top"
                    data-aos-delay="600"
                  >
                    <img
                      src="assets/images/home/sun-energy/Trees.svg"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="plants"
                    data-aos="zoom-in"
                    data-aos-delay="800"
                  >
                    <img
                      src="assets/images/home/sun-energy/Plants.svg"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="clouds"
                    data-aos="zoom-in"
                    data-aos-delay="700"
                  >
                    <img
                      src="assets/images/home/sun-energy/Clouds.svg"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="sun"
                    data-aos="fade-up"
                    data-aos-delay="1100"
                  >
                    <img
                      src="assets/images/home/sun-energy/Sun.svg"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="arrows"
                    data-aos="zoom-out"
                    data-aos-delay="1300"
                  >
                    <img
                      src="assets/images/home/sun-energy/Arrows.svg"
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="5" md="5" xs={12}>
                <div className="calculator">
                  <span className="background"
                  data-aos="zoom-out"
                  data-aos-delay="200">
                    <img
                      src="assets/images/home/calculator/Vector.svg"
                      alt="image_not_found"
                    />
                  </span>
                  <span className="circle"
                  data-aos="zoom-out"
                  data-aos-delay="200">
                    <img
                      src="assets/images/home/calculator/Circle.svg"
                      alt="image_not_found"
                    />
                  </span>
                  <span className="skull"
                  data-aos="zoom-out"
                  data-aos-delay="200">
                    <img
                      src="assets/images/home/calculator/Skull.svg"
                      alt="image_not_found"
                    />
                  </span>
                  <span className="magnifier"
                  data-aos="zoom-out"
                  data-aos-delay="200">
                    <img
                      src="assets/images/home/calculator/Magnifying Glass.svg"
                      alt="image_not_found"
                    />
                  </span>
                  <span className="land"
                  data-aos="zoom-out"
                  data-aos-delay="200">
                    <img
                      src="assets/images/home/calculator/Land.svg"
                      alt="image_not_found"
                    />
                  </span>
                  <span className="calculator"
                  data-aos="zoom-out"
                  data-aos-delay="200">
                    <img
                      src="assets/images/home/calculator/Calculator.svg"
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>

              <Col lg="5" md="5" xs={12}>
                <div className="feature-content">
                  <div  className="section-title mb-200">
                    <p className="mb-0" style={{fontSize:'1.5rem', color:'black'}}>
                      <span style={{fontFamily:'ProximaNova-Bold'}}>Offsets</span> are measured in tonnes of carbon dioxide equivalent (CO2e).  CO2 is not the only GHG produced by emissions but does account for ~76% of total emissions. 
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center mb-5">
              <Col lg="5" md="5" xs={12}>
                <div className="feature-content feature-gases">
                  <div className="section-title mb-200">
                    <p className="mb-0" style={{fontSize:'1.5rem', color:'black'}}>
                      The term <span style={{fontFamily:'ProximaNova-Bold'}}>GHG</span> also refers to other compounds, such as methane, nitrous oxide, etc. that must also be accounted for.
                    </p>
                  </div>
                </div>
              </Col>

              <Col lg="6" md="6" xs={12}>
                <div className="gases">
                  <span
                    className="background"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <img
                      src="assets/images/home/gases/Vector.svg"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="methane"
                    data-aos="zoom-in"
                    data-aos-delay="400"
                  >
                    <img
                      src="assets/images/home/gases/Methane.svg"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="flourinated"
                    data-aos="zoom-in"
                    data-aos-delay="600"
                  >
                    <img
                      src="assets/images/home/gases/Flourinated.svg"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="nitrous"
                    data-aos="zoom-in"
                    data-aos-delay="800"
                  >
                    <img
                      src="assets/images/home/gases/Nitrous.svg"
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="5" md="5" xs={12}>
                <div className="reason">
                  <span className="background"
                  data-aos="fade-in"
                  data-aos-delay="200">
                    <img
                      src="assets/images/home/reason/Vector.svg"
                      alt="image_not_found"
                    />
                  </span>
                  <span className="ev"
                  data-aos="zoom-in"
                  data-aos-delay="200">
                    <img
                      src="assets/images/home/reason/EV.svg"
                      alt="image_not_found"
                    />
                  </span>
                  <span className="planting"
                  data-aos="zoom-out"
                  data-aos-delay="400">
                    <img
                      src="assets/images/home/reason/Planting.svg"
                      alt="image_not_found"
                    />
                  </span>
                  <span className="solar"
                  data-aos="zoom-in"
                  data-aos-delay="800">
                    <img
                      src="assets/images/home/reason/Solar.svg"
                      alt="image_not_found"
                    />
                  </span>
                  <span className="wind"
                  data-aos="zoom-out"
                  data-aos-delay="600">
                    <img
                      src="assets/images/home/reason/Wind.svg"
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>

              <Col lg="5" md="5" xs={12}>
                <div className="feature-content feature-reason">
                  <div  className="section-title mb-200">
                    <p className="mb-0" style={{fontSize:'1.5rem', color:'black'}}>
                    For this reason, carbon offset equivalents accounts for the global warming potential of the various GHG.  
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </Fragment>
  );
};

export default featureThree;
