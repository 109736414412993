import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { UncontrolledCollapse } from "reactstrap";
const SideBar = ({ sidebarIsOpen, handleSidebar }) => {
  return (
    <Fragment>
      <div className="sidebar-menu-wrapper">
        <div
          id="sidebar-menu"
          className={`sidebar-menu ${sidebarIsOpen ? "active" : null}`}
        >
          <div className="dismiss text-right mb-60 clearfix">
            <span className="close-btn" onClick={handleSidebar}>
              <i className="flaticon-cancel-music"></i>
            </span>
          </div>
          <ul className="menu-list mb-60 list-unstyled components clearfix">
          <li className="menu-item-has-child active">
              <Link to="/" id="toggler0">
                Home
              </Link>
            </li>
            <li className="menu-item-has-child active">
              <Link to="/more-info" id="toggler0">
                Carbon Offsets
              </Link>
            </li>
            <li className="menu-item-has-child active">
              <Link to="/about-us" id="toggler0">
                About Us
              </Link>
            </li>
            <li>
              <a href="/#contact-section">Contact Us</a>
            </li>
          </ul>

          <div className="contact-info ul-li-block mb-60 clearfix">
            <h2 className="sidebar-title mb-30">contact info</h2>
            <ul className="clearfix">
              <li>
                <a style={{color:'black'}} target="_blank" href="https://www.google.com/maps/place/2843+NW+Lolo+Dr,+Bend,+OR+97701,+USA/@44.0531942,-121.3650958,17z/data=!3m1!4b1!4m5!3m4!1s0x54b8c8129dc507fb:0xc91ec6886b9dfdf!8m2!3d44.0531904!4d-121.3629071">
                  <small className="icon">
                    <i className="uil uil-location-point"></i>
                  </small>
                  <span className="info-text">
                    2843 NW Lolo Drive, Bend, OR 97703
                  </span>
                </a>
              </li>
              <li>
                <span className="icon">
                  <i className="uil uil-envelope-alt"></i>
                </span>
                info@vacaygreen.com
              </li>
            </ul>
          </div>
        </div>
        <div
          onClick={handleSidebar}
          className={`overlay ${sidebarIsOpen ? "active" : null}`}
        ></div>
      </div>
    </Fragment>
  );
};

export default SideBar;
