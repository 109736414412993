import React, { Fragment } from "react";
import { Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";
import TitleSection from "../../components/Titlesection/TitleSection";
import PageTitle from "../../components/utility";
import { LayoutOne } from "../../layouts";

import classnames from "classnames";

const Privacy = () => {
  return (
    <Fragment>
      <PageTitle
        PageTitle="VacayGreen"
        favicon="assets/images/logo/vg-icon-sq.png"
      />
      <LayoutOne activeClass="active" wrapperClass="about-section">
        <main className="about-content">
          <section>
            <Container className="mt-50">
              <Row>
                <Col sm="12" className="mt-100">
                  {
                    <TitleSection spacer="mb-100">
                      <div className="big-title-text mb-30">Privacy Policy</div>
                      <div className="title-text mb-30">
                        We are making this planet greener one Vacation at a
                        time.
                      </div>
                      <p>
                        Thank you for choosing to be part of our community at
                        Vacaygreen (“Company“, “we“, “us“, “our“). We are
                        committed to protecting your personal information and
                        your right to privacy. If you have any questions or
                        concerns about this privacy notice, or our practices
                        with regards to your personal information, please
                        contact us at support@vacaygreen.com. When you visit our
                        website https://www.vacaygreen.com (the “Website“), and
                        more generally, use any of our services (the “Services“,
                        which include the Website), we appreciate that you are
                        trusting us with your personal information. We take your
                        privacy very seriously. In this privacy notice, we seek
                        to explain to you in the clearest way possible what
                        information we collect, how we use it and what rights
                        you have in relation to it. We hope you take some time
                        to read through it carefully, as it is important. If
                        there are any terms in this privacy notice that you do
                        not agree with, please discontinue use of our Services
                        immediately. This privacy notice applies to all
                        information collected through our Services (which, as
                        described above, includes our Website), as well as, any
                        related services, sales, marketing or events. Please
                        read this privacy notice carefully as it will help you
                        understand what we do with the information that we
                        collect. 1. WHAT INFORMATION DO WE COLLECT? Personal
                        information you disclose to us In Short: We collect
                        personal information that you provide to us. We collect
                        personal information that you voluntarily provide to us
                        when you express an interest in obtaining information
                        about us or our products and Services, when you
                        participate in activities on the Website or otherwise
                        when you contact us. The personal information that we
                        collect depends on the context of your interactions with
                        us and the Website, the choices you make and the
                        products and features you use. The personal information
                        we collect may include the following: Personal
                        Information Provided by You. We collect names; phone
                        numbers; email addresses; and other similar information.
                        All personal information that you provide to us must be
                        true, complete and accurate, and you must notify us of
                        any changes to such personal information. Information
                        automatically collected In Short: Some information —
                        such as your Internet Protocol (IP) address and/or
                        browser and device characteristics — is collected
                        automatically when you visit our Website. We
                        automatically collect certain information when you
                        visit, use or navigate the Website. This information
                        does not reveal your specific identity (like your name
                        or contact information) but may include device and usage
                        information, such as your IP address, browser and device
                        characteristics, operating system, language preferences,
                        referring URLs, device name, country, location,
                        information about how and when you use our Website and
                        other technical information. This information is
                        primarily needed to maintain the security and operation
                        of our Website, and for our internal analytics and
                        reporting purposes. Like many businesses, we also
                        collect information through cookies and similar
                        technologies. The information we collect includes:
                      </p>
                      <p>
                        {" "}
                        <strong>Log and Usage Data.</strong> Log and usage data is
                        service-related, diagnostic, usage and performance
                        information our servers automatically collect when you
                        access or use our Website and which we record in log
                        files. Depending on how you interact with us, this log
                        data may include your IP address, device information,
                        browser type and settings and information about your
                        activity in the Website (such as the date/time stamps
                        associated with your usage, pages and files viewed,
                        searches and other actions you take such as which
                        features you use), device event information (such as
                        system activity, error reports (sometimes called 'crash
                        dumps') and hardware settings).
                      </p>
                      <p>
                        {" "}
                        <strong>Device Data.</strong> We collect device data such as information
                        about your computer, phone, tablet or other device you
                        use to access the Website. Depending on the device used,
                        this device data may include information such as your IP
                        address (or proxy server), device and application
                        identification numbers, location, browser type, hardware
                        model Internet service provider and/or mobile carrier,
                        operating system and system configuration information.
                      </p>
                      <p>
                        {" "}
                        <strong>Location Data.</strong> We collect location data such as
                        information about your device's location, which can be
                        either precise or imprecise. How much information we
                        collect depends on the type and settings of the device
                        you use to access the Website. For example, we may use
                        GPS and other technologies to collect geolocation data
                        that tells us your current location (based on your IP
                        address). You can opt out of allowing us to collect this
                        information either by refusing access to the information
                        or by disabling your Location setting on your device.
                        Note however, if you choose to opt out, you may not be
                        able to use certain aspects of the Services. 2. HOW DO
                        WE USE YOUR INFORMATION? In Short: We process your
                        information for purposes based on legitimate business
                        interests, the fulfillment of our contract with you,
                        compliance with our legal obligations, and/or your
                        consent. We use personal information collected via our
                        Website for a variety of business purposes described
                        below. We process your personal information for these
                        purposes in reliance on our legitimate business
                        interests, in order to enter into or perform a contract
                        with you, with your consent, and/or for compliance with
                        our legal obligations. We indicate the specific
                        processing grounds we rely on next to each purpose
                        listed below. We use the information we collect or
                        receive:
                      </p>
                      <p>
                        {" "}
                        To facilitate account creation and logon process. If you
                        choose to link your account with us to a third-party
                        account (such as your Google or Facebook account), we
                        use the information you allowed us to collect from those
                        third parties to facilitate account creation and logon
                        process for the performance of the contract.
                      </p>
                      <p>
                        {" "}
                        To post testimonials. We post testimonials on our
                        Website that may contain personal information. Prior to
                        posting a testimonial, we will obtain your consent to
                        use your name and the content of the testimonial. If you
                        wish to update, or delete your testimonial, please
                        contact us at support@Vacaygreen.com and be sure to
                        include your name, testimonial location, and contact
                        information.
                      </p>
                      <p>
                        {" "}
                        Request feedback. We may use your information to request
                        feedback and to contact you about your use of our
                        Website.
                      </p>
                      <p>
                        {" "}
                        To enable user-to-user communications. We may use your
                        information in order to enable user-to-user
                        communications with each user's consent.
                      </p>
                      <p>
                        {" "}
                        To manage user accounts. We may use your information for
                        the purposes of managing our account and keeping it in
                        working order.
                      </p>
                      <p>
                        {" "}
                        To send administrative information to you. We may use
                        your personal information to send you product, service
                        and new feature information and/or information about
                        changes to our terms, conditions, and policies.
                      </p>
                      <p>
                        {" "}
                        To protect our Services. We may use your information as
                        part of our efforts to keep our Website safe and secure
                        (for example, for fraud monitoring and prevention).
                      </p>
                      <p>
                        {" "}
                        To enforce our terms, conditions and policies for
                        business purposes, to comply with legal and regulatory
                        requirements or in connection with our contract.
                      </p>
                      <p>
                        {" "}
                        To respond to legal requests and prevent harm. If we
                        receive a subpoena or other legal request, we may need
                        to inspect the data we hold to determine how to respond.
                      </p>
                      <p>
                        {" "}
                        Fulfill and manage your orders. We may use your
                        information to fulfill and manage your orders, payments,
                        returns, and exchanges made through the Website.
                      </p>
                      <p>
                        {" "}
                        Administer prize draws and competitions. We may use your
                        information to administer prize draws and competitions
                        when you elect to participate in our competitions.
                      </p>
                      <p>
                        {" "}
                        To deliver and facilitate delivery of services to the
                        user. We may use your information to provide you with
                        the requested service.
                      </p>
                      <p>
                        {" "}
                        To respond to user inquiries/offer support to users. We
                        may use your information to respond to your inquiries
                        and solve any potential issues you might have with the
                        use of our Services.
                      </p>
                      <p>
                        {" "}
                        To send you marketing and promotional communications. We
                        and/or our third-party marketing partners may use the
                        personal information you send to us for our marketing
                        purposes, if this is in accordance with your marketing
                        preferences. For example, when expressing an interest in
                        obtaining information about us or our Website,
                        subscribing to marketing or otherwise contacting us, we
                        will collect personal information from you. You can
                        opt-out of our marketing emails at any time (see the
                        “WHAT ARE YOUR PRIVACY RIGHTS” below).
                      </p>
                      <p>
                        {" "}
                        Deliver targeted advertising to you. We may use your
                        information to develop and display personalized content
                        and advertising (and work with third parties who do so)
                        tailored to your interests and/or location and to
                        measure its effectiveness.
                      </p>
                      <p>
                        {" "}
                        For other business purposes. We may use your information
                        for other business purposes, such as data analysis,
                        identifying usage trends, determining the effectiveness
                        of our promotional campaigns and to evaluate and improve
                        our Website, products, marketing and your experience. We
                        may use and store this information in aggregated and
                        anonymized form so that it is not associated with
                        individual end users and does not include personal
                        information. We will not use identifiable personal
                        information without your consent. 3. WILL YOUR
                        INFORMATION BE SHARED WITH ANYONE? In Short: We only
                        share information with your consent, to comply with
                        laws, to provide you with services, to protect your
                        rights, or to fulfill business obligations. We may
                        process or share your data that we hold based on the
                        following legal basis:
                      </p>
                      <p>
                        {" "}
                        <strong>Consent:</strong> We may process your data if you have given us
                        specific consent to use your personal information for a
                        specific purpose.
                      </p>
                      <p>
                        {" "}
                        <strong>Legitimate Interests:</strong> We may process your data when it
                        is reasonably necessary to achieve our legitimate
                        business interests.
                      </p>
                      <p>
                        {" "}
                        <strong>Performance of a Contract:</strong> Where we have entered into a
                        contract with you, we may process your personal
                        information to fulfill the terms of our contract.
                      </p>
                      <p>
                        {" "}
                        <strong>Legal Obligations:</strong> We may disclose your information
                        where we are legally required to do so in order to
                        comply with applicable law, governmental requests, a
                        judicial proceeding, court order, or legal process, such
                        as in response to a court order or a subpoena (including
                        in response to public authorities to meet national
                        security or law enforcement requirements).
                      </p>
                      <p>
                        {" "}
                        <strong>Vital Interests:</strong> We may disclose your information where
                        we believe it is necessary to investigate, prevent, or
                        take action regarding potential violations of our
                        policies, suspected fraud, situations involving
                        potential threats to the safety of any person and
                        illegal activities, or as evidence in litigation in
                        which we are involved. More specifically, we may need to
                        process your data or share your personal information in
                        the following situations:
                      </p>
                      <p>
                        {" "}
                        Business Transfers. We may share or transfer your
                        information in connection with, or during negotiations
                        of, any merger, sale of company assets, financing, or
                        acquisition of all or a portion of our business to
                        another company. 4. DO WE USE COOKIES AND OTHER TRACKING
                        TECHNOLOGIES? In Short: We may use cookies and other
                        tracking technologies to collect and store your
                        information. We may use cookies and similar tracking
                        technologies (like web beacons and pixels) to access or
                        store information. Specific information about how we use
                        such technologies and how you can refuse certain cookies
                        is set out in our Cookie Notice. 5. HOW LONG DO WE KEEP
                        YOUR INFORMATION? In Short: We keep your information for
                        as long as necessary to fulfill the purposes outlined in
                        this privacy notice unless otherwise required by law. We
                        will only keep your personal information for as long as
                        it is necessary for the purposes set out in this privacy
                        notice, unless a longer retention period is required or
                        permitted by law (such as tax, accounting or other legal
                        requirements). When we have no ongoing legitimate
                        business need to process your personal information, we
                        will either delete or anonymize such information, or, if
                        this is not possible (for example, because your personal
                        information has been stored in backup archives), then we
                        will securely store your personal information and
                        isolate it from any further processing until deletion is
                        possible. 6. HOW DO WE KEEP YOUR INFORMATION SAFE? In
                        Short: We aim to protect your personal information
                        through a system of organizational and technical
                        security measures. We have implemented appropriate
                        technical and organizational security measures designed
                        to protect the security of any personal information we
                        process. However, despite our safeguards and efforts to
                        secure your information, no electronic transmission over
                        the Internet or information storage technology can be
                        guaranteed to be 100% secure, so we cannot promise or
                        guarantee that hackers, cybercriminals, or other
                        unauthorized third parties will not be able to defeat
                        our security, and improperly collect, access, steal, or
                        modify your information. Although we will do our best to
                        protect your personal information, transmission of
                        personal information to and from our Website is at your
                        own risk. You should only access the Website within a
                        secure environment. 7. DO WE COLLECT INFORMATION FROM
                        MINORS? In Short: We do not knowingly collect data from
                        or market to children under 18 years of age. We do not
                        knowingly solicit data from or market to children under
                        18 years of age. By using the Website, you represent
                        that you are at least 18 or that you are the parent or
                        guardian of such a minor and consent to such minor
                        dependent's use of the Website. If we learn that
                        personal information from users less than 18 years of
                        age has been collected, we will deactivate the account
                        and take reasonable measures to promptly delete such
                        data from our records. If you become aware of any data
                        we may have collected from children under age 18, please
                        contact us at support@Vacaygreen.com. 8. WHAT ARE YOUR
                        PRIVACY RIGHTS? In Short: You may review, change, or
                        terminate your account at any time. If you are a
                        resident in the European Economic Area and you believe
                        we are unlawfully processing your personal information,
                        you also have the right to complain to your local data
                        protection supervisory authority. You can find their
                        contact details here:
                        http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
                        If you are a resident in Switzerland, the contact
                        details for the data protection authorities are
                        available here:
                        https://www.edoeb.admin.ch/edoeb/en/home.html. Cookies
                        and similar technologies: Most Web browsers are set to
                        accept cookies by default. If you prefer, you can
                        usually choose to set your browser to remove cookies and
                        to reject cookies. If you choose to remove cookies or
                        reject cookies, this could affect certain features or
                        services of our Website. To opt-out of interest-based
                        advertising by advertisers on our Website visit
                        http://www.aboutads.info/choices/. 9. CONTROLS FOR
                        DO-NOT-TRACK FEATURES Most web browsers and some mobile
                        operating systems and mobile applications include a
                        Do-Not-Track (“DNT”) feature or setting you can activate
                        to signal your privacy preference not to have data about
                        your online browsing activities monitored and collected.
                        At this stage no uniform technology standard for
                        recognizing and implementing DNT signals has been
                        finalized. As such, we do not currently respond to DNT
                        browser signals or any other mechanism that
                        automatically communicates your choice not to be tracked
                        online. If a standard for online tracking is adopted
                        that we must follow in the future, we will inform you
                        about that practice in a revised version of this privacy
                        notice. 10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC
                        PRIVACY RIGHTS? In Short: Yes, if you are a resident of
                        California, you are granted specific rights regarding
                        access to your personal information. California Civil
                        Code Section 1798.83, also known as the “Shine The
                        Light” law, permits our users who are California
                        residents to request and obtain from us, once a year and
                        free of charge, information about categories of personal
                        information (if any) we disclosed to third parties for
                        direct marketing purposes and the names and addresses of
                        all third parties with which we shared personal
                        information in the immediately preceding calendar year.
                        If you are a California resident and would like to make
                        such a request, please submit your request in writing to
                        us using the contact information provided below. If you
                        are under 18 years of age, reside in California, and
                        have a registered account with the Website, you have the
                        right to request removal of unwanted data that you
                        publicly post on the Website. To request removal of such
                        data, please contact us using the contact information
                        provided below, and include the email address associated
                        with your account and a statement that you reside in
                        California. We will make sure the data is not publicly
                        displayed on the Website, but please be aware that the
                        data may not be completely or comprehensively removed
                        from all our systems (e.g. backups, etc.). CCPA Privacy
                        Notice The California Code of Regulations defines a
                        “resident” as: (1) every individual who is in the State
                        of California for other than a temporary or transitory
                        purpose and (2) every individual who is domiciled in the
                        State of California who is outside the State of
                        California for a temporary or transitory purpose All
                        other individuals are defined as “non-residents.” If
                        this definition of “resident” applies to you, we must
                        adhere to certain rights and obligations regarding your
                        personal information. What categories of personal
                        information do we collect? We have collected the
                        following categories of personal information in the past
                        twelve (12) months: first name, last name, phone number,
                        company, and email address. We may also collect other
                        personal information outside of these categories
                        instances where you interact with us in-person, online,
                        or by phone or mail in the context of:
                      </p>
                      <p>
                        {" "}
                        Receiving help through our customer support channels;
                      </p>
                      <p> Participation in customer surveys or contests; and</p>
                      <p>
                        {" "}
                        Facilitation in the delivery of our Services and to
                        respond to your inquiries. How do we use and share your
                        personal information? More information about our data
                        collection and sharing practices can be found in this
                        privacy notice. You may contact us by email at
                        support@vacaygreen.com, or by referring to the contact
                        details at the bottom of this document. If you are using
                        an authorized agent to exercise your right to opt-out we
                        may deny a request if the authorized agent does not
                        submit proof that they have been validly authorized to
                        act on your behalf. Will your information be shared with
                        anyone else? We may disclose your personal information
                        with our service providers pursuant to a written
                        contract between us and each service provider. Each
                        service provider is a for-profit entity that processes
                        the information on our behalf. We may use your personal
                        information for our own business purposes, such as for
                        undertaking internal research for technological
                        development and demonstration. This is not considered to
                        be “selling” of your personal data. Vacaygreen has not
                        disclosed or sold any personal information to third
                        parties for a business or commercial purpose in the
                        preceding 12 months. Vacaygreen will not sell personal
                        information in the future belonging to website visitors,
                        users and other consumers. Your rights with respect to
                        your personal data Right to request deletion of the data
                        - Request to delete You can ask for the deletion of your
                        personal information. If you ask us to delete your
                        personal information, we will respect your request and
                        delete your personal information, subject to certain
                        exceptions provided by law, such as (but not limited to)
                        the exercise by another consumer of his or her right to
                        free speech, our compliance requirements resulting from
                        a legal obligation or any processing that may be
                        required to protect against illegal activities. Right to
                        be informed - Request to know Depending on the
                        circumstances, you have a right to know:
                      </p>
                      <p>
                        {" "}
                        whether we collect and use your personal information;
                      </p>
                      <p>
                        {" "}
                        the categories of personal information that we collect;
                      </p>
                      <p>
                        {" "}
                        the purposes for which the collected personal
                        information is used;
                      </p>
                      <p>
                        {" "}
                        whether we sell your personal information to third
                        parties;
                      </p>
                      <p>
                        {" "}
                        the categories of personal information that we sold or
                        disclosed for a business purpose;
                      </p>
                      <p>
                        {" "}
                        the categories of third parties to whom the personal
                        information was sold or disclosed for a business
                        purpose; and
                      </p>
                      <p>
                        {" "}
                        the business or commercial purpose for collecting or
                        selling personal information. In accordance with
                        applicable law, we are not obligated to provide or
                        delete consumer information that is de-identified in
                        response to a consumer request or to re-identify
                        individual data to verify a consumer request. Right to
                        Non-Discrimination for the Exercise of a Consumer's
                        Privacy Rights We will not discriminate against you if
                        you exercise your privacy rights. Verification process
                        Upon receiving your request, we will need to verify your
                        identity to determine you are the same person about whom
                        we have the information in our system. These
                        verification efforts require us to ask you to provide
                        information so that we can match it with information you
                        have previously provided us. For instance, depending on
                        the type of request you submit, we may ask you to
                        provide certain information so that we can match the
                        information you provide with the information we already
                        have on file, or we may contact you through a
                        communication method (e.g. phone or email) that you have
                        previously provided to us. We may also use other
                        verification methods as the circumstances dictate. We
                        will only use personal information provided in your
                        request to verify your identity or authority to make the
                        request. To the extent possible, we will avoid
                        requesting additional information from you for the
                        purposes of verification. If, however, if we cannot
                        verify your identity from the information already
                        maintained by us, we may request that you provide
                        additional information for the purposes of verifying
                        your identity, and for security or fraud-prevention
                        purposes. We will delete such additionally provided
                        information as soon as we finish verifying you. Other
                        privacy rights
                      </p>
                      <p>
                        {" "}
                        you may object to the processing of your personal data
                      </p>
                      <p>
                        {" "}
                        you may request correction of your personal data if it
                        is incorrect or no longer relevant, or ask to restrict
                        the processing of the data
                      </p>
                      <p>
                        {" "}
                        you can designate an authorized agent to make a request
                        under the CCPA on your behalf. We may deny a request
                        from an authorized agent that does not submit proof that
                        they have been validly authorized to act on your behalf
                        in accordance with the CCPA.
                      </p>
                      <p>
                        {" "}
                        you may request to opt-out from future selling of your
                        personal information to third parties. Upon receiving a
                        request to opt-out, we will act upon the request as soon
                        as feasibly possible, but no later than 15 days from the
                        date of the request submission. To exercise these
                        rights, you can contact us by email at
                        support@Vacaygreen.com, or by referring to the contact
                        details at the bottom of this document. If you have a
                        complaint about how we handle your data, we would like
                        to hear from you. 11. DO WE MAKE UPDATES TO THIS NOTICE?
                        In Short: Yes, we will update this notice as necessary
                        to stay compliant with relevant laws. We may update this
                        privacy notice from time to time. The updated version
                        will be indicated by an updated “Revised” date and the
                        updated version will be effective as soon as it is
                        accessible. If we make material changes to this privacy
                        notice, we may notify you either by prominently posting
                        a notice of such changes or by directly sending you a
                        notification. We encourage you to review this privacy
                        notice frequently to be informed of how we are
                        protecting your information. 12. HOW CAN YOU CONTACT US
                        ABOUT THIS NOTICE? If you have questions or comments
                        about this notice, you may email us at
                        support@Vacaygreen.com or by post to: Vacaygreen 303
                        West Wall Street Suite 2400 Midland, TX 79701 United
                        States 13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE
                        DATA WE COLLECT FROM YOU? Based on the applicable laws
                        of your country, you may have the right to request
                        access to the personal information we collect from you,
                        change that information, or delete it in some
                        circumstances. To request to review, update, or delete
                        your personal information, please visit:
                        behome@Vacaygreen.com. We will respond to your request
                        within 30 days.
                      </p>
                    </TitleSection>
                  }
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </LayoutOne>
    </Fragment>
  );
};

export default Privacy;
