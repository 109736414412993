import React, { Fragment } from "react";
import { Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";
import TitleSection from "../../components/Titlesection/TitleSection";
import PageTitle from "../../components/utility";
import { LayoutOne } from "../../layouts";

import classnames from "classnames";

const Terms = () => {
  return (
    <Fragment>
      <PageTitle
        PageTitle="VacayGreen"
        favicon="assets/images/logo/vg-icon-sq.png"
      />
      <LayoutOne activeClass="active" wrapperClass="about-section">
        <main className="about-content">
          <section>
            <Container className="mt-50">
              <Row>
                <Col sm="12" className="mt-100">
                  {
                    <TitleSection spacer="mb-100">
                      <div className="big-title-text mb-30">Terms of use</div>
                      <p>
                        Terms of use THESE TERMS OF SERVICE (“TERMS” ), TOGETHER
                        WITH THE PURCHASE ORDER, ARE A LEGAL AGREEMENT BETWEEN
                        THE “CLIENT” IDENTIFIED IN SUCH PURCHASE ORDER AND
                        VACAYGREEN, LLC, A DELAWARE LIMITED LIABILITY COMPANY
                        DBA VACAYGREEN. BY EXECUTING A PURCHASE ORDER THAT
                        INCLUDES THESE TERMS BY REFERENCE, CLIENT ACKNOWLEDGES
                        THAT CLIENT HAS REVIEWED AND ACCEPTS THESE TERMS. IF YOU
                        ARE AGREEING TO THESE TERMS AS AN INDIVIDUAL “CLIENT”
                        REFERS TO YOU INDIVIDUALLY. IF YOU ARE AGREEING TO THESE
                        TERMS AS A REPRESENTATIVE OF AN ENTITY, YOU REPRESENT
                        THAT YOU HAVE THE AUTHORITY TO BIND THAT ENTITY AND
                        “CLIENT” REFERS TO THAT ENTITY AND ALL THE USERS
                        SPECIFIED IN THE PURCHASE ORDER. IF CLIENT DOES NOT
                        AGREE WITH ALL OF THESE TERMS, DO NOT ACCESS OR
                        OTHERWISE USE THE SERVICES. VACAYGREEN MAY MAKE CHANGES
                        TO THE SERVICES AT ANY TIME. IN ADDITION, VACAYGREEN MAY
                        MAKE CHANGES TO THESE TERMS AT ANY TIME, AND WILL
                        PROVIDE NOTICE TO CLIENT OF SUCH CHANGES BY POSTING A
                        MESSAGE ON SITE OR SITES USED TO ACCESS THE SERVICES
                        NOTIFYING CLIENT OF THE UPDATES TO THESE TERMS AND
                        REQUESTING THAT CLIENT REVIEW SUCH UPDATED TERMS.
                        CLIENT’S CONTINUED USE OF THE SERVICES AFTER SUCH
                        CHANGES HAVE BEEN POSTED WILL BE CONSIDERED AS CLIENT’ S
                        CONSENT TO AND ACCEPTANCE OF THE REVISED TERMS.
                      </p>
                      <p></p>
                      <p>
                        1. DEFINITIONS. 1.1 “Agreement” means these Terms and
                        the Purchase Order, collectively. 1.2 “Client Data”
                        means all data submitted, stored, posted, displayed, or
                        otherwise transmitted by or on behalf of Client or any
                        User and received and analyzed by the Services and
                        including, without limitation, Occupant Data. 1.3
                        “Client System” means Client’s internal website(s),
                        servers and other equipment and software used in the
                        conduct of Client’s business. 1.4 “Documentation” means
                        the online user instructions and help files made
                        available by VacayGreen for use with the Services, as
                        may be updated from time to time by VacayGreen. 1.5
                        “Fees” means the costs associated VacayGreen software
                        and carbon offset purchases. 1.6 “Intellectual Property
                        Rights” means all intellectual property rights or
                        similar proprietary rights, including (a) patent rights
                        and utility models, (b) copyrights and database rights,
                        (c) trademarks, trade names, domain names and trade
                        dress and the goodwill associated therewith, (d) trade
                        secrets, (e) mask works, and (f) industrial design
                        rights; in each case, including any registrations of,
                        applications to register, and renewals and extensions
                        of, any of the foregoing in any jurisdiction in the
                        world. 1.7 “Malicious Code” means viruses, worms, time
                        bombs, Trojan horses and other harmful or malicious
                        code, files, scripts, agents or programs. 1.8 “Occupant
                        Data” means all data submitted, stored, posted,
                        displayed, or otherwise transmitted by or on behalf of
                        Client or any User and received and analyzed by the
                        Services and regarding occupants or prospective
                        occupants of Properties, Property owners, or customers
                        or prospective customers of Client. 1.9 “Occupied
                        Nights” means the number of nights a Property has been
                        reserved for occupancy or otherwise occupied by guests
                        during the relevant monthly period as set forth in
                        Subsection 7.
                      </p>
                      <p></p>
                      <p>
                        2. 1.10 “Purchase Order” means such ordering documents
                        for the Services that are executed hereunder by Client
                        which have been accepted by VacayGreen, from time to
                        time, including modifications, supplements and addend
                        thereto. 1.11 “Users” means Client’s employees,
                        consultants, contractors, agents and third parties with
                        whom Client may transact business and (a) for whom
                        access to the Services during the Subscription Term has
                        been purchased pursuant to a Purchase Order, (b) who are
                        authorized by Client to access and use the Services and
                        (c) who have been supplied user identifications and
                        passwords for such purpose by Client.
                      </p>
                      <p></p>
                      <p>
                        2. ORDERS; LICENSES; AND RESTRICTIONS. 2.1 Orders.
                        Subject to the terms and conditions contained in these
                        Terms, Client may purchase subscriptions to use the
                        Services pursuant to Purchase Orders. User subscriptions
                        are for designated Users only and cannot be shared or
                        used by more than one User, but may be reassigned to new
                        Users replacing former Users who no longer require
                        ongoing use of the Services. Client agrees that its
                        purchases hereunder are neither contingent on the
                        delivery of any future functionality or features nor
                        dependent on any oral or written public comments made by
                        VacayGreen regarding any future functionality or
                        features. If there is any inconsistency between a
                        Purchase Order and these Terms, the Purchase Order shall
                        control. 2.2 Access and Use License. VacayGreen hereby
                        grants to Client a limited, non-exclusive, non¬
                        transferable right for its Users to access and use the
                        Services for during the Subscription Term, subject to
                        the terms and conditions of, and compliance with, this
                        Agreement and the Documentation. Such use of the
                        Services shall be solely for Client’ s internal business
                        purposes and not for the benefit of any other person or
                        entity. Client’s use of the Services may be subject to
                        certain limitations, such as, for example, limits on
                        storage capacity for Client Data. Any such limitations
                        will be specified either in the Purchase Order or in the
                        Documentation. 2.3 Restrictions. Client shall not,
                        directly or indirectly, and Client shall not permit any
                        User or third party to: (a) reverse engineer, decompile,
                        disassemble or otherwise attempt to discover the object
                        code, source code or underlying ideas or algorithms of
                        the Services or the Software; (b) tinker with, modify,
                        translate, or create derivative works based on any
                        element of the Services, the Software, or any related
                        Documentation; 2.4 Reservation of Rights. Except as
                        expressly granted in these Terms, there are no other
                        licenses granted to Client, express, implied or by way
                        of estoppel. All rights not granted in these Terms are
                        reserved by VacayGreen.{" "}
                      </p>
                      <p></p>
                      <p>
                        3. THIRD PARTY OFFERINGS. 3.1 Use of Third Party
                        Offerings. VacayGreen or third parties may from time to
                        time make Third Party Offerings available to Client in
                        connection with Client’s use of the Services. Without
                        limiting the generality of Section 8.2, VacayGreen does
                        not warrant or support any of the Third Party Offerings,
                        whether or not they are designated by VacayGreen as
                        “certified” or otherwise, except as specified in a
                        Purchase Order. Client acknowledges that VacayGreen may
                        allow providers of Third Party Offerings to access
                        Client Data or Other Information (as defined in Section
                        11.2) as required for the interoperation and support of
                        such Third Party Offerings with the Services. VacayGreen
                        shall not be responsible for any disclosure,
                        modification or deletion of Client Data resulting from
                        any such access by the providers of Third Party
                        Offerings. 3.2 Integration with Third Party Offerings.
                        The services may contain features designed to
                        interoperate with Third Party Offerings (e.g.,
                        Streamline, Track, Barefoot, VRBO, HomeAway, Airbnb). To
                        use such features, Client may be required to obtain
                        access to such Third Party Offerings from their
                        providers. Should Client contract directly with a
                        provider of any Third Party Offering, any such
                        relationship, and any exchange of data between Client
                        and any provider of a Third Party Offering, is solely
                        between Client and the applicable provider of the Third
                        Party Offering. If the provider of any of the Third
                        Party Offerings ceases to make any of such Third Party
                        Offerings available for interoperation with the
                        corresponding Services features or Software on
                        reasonable terms, VacayGreen may cease providing such
                        features without entitling Client to any refund, credit,
                        or other compensation and/or VacayGreen may exercise its
                        right of termination under Subsection 13.1.4 Client shall at all times be solely responsible for
                        ensuring that its use of the VacayGreen Services and
                        Client’s interactions with Third Party Offerings
                        providers are compliant with the terms and conditions
                        governing the use of and access to such Third Party
                        Offerings. 3.3 Third Party Hosting. VacayGreen may use
                        the services of one or more third parties to host all or
                        part of the Services. VacayGreen will pass-through any
                        warranties to the extent that VacayGreen receives any
                        from it then current third-party hosting provider that
                        it can provide to Client. Client agrees to comply with
                        any acceptable use policies and other terms of any
                        third-party hosting provider that are provided or
                        otherwise made available to Client from time to time.{" "}
                      </p>
                      <p>
                        4. PASSWORDS; SECURITY. 4.1 Passwords. VacayGreen will
                        issue Client an initial administrator user login and
                        password and Client may create user logins and passwords
                        for each of its Users. Client shall be, and shall ensure
                        that each of its Users are, responsible for maintaining
                        the confidentiality of all user logins and passwords and
                        for ensuring that each user login and password is used
                        only by the User lo which ii was issued. Client is
                        solely responsible for any and all access and use of the
                        Services that occurs using logins and passwords issued
                        to Client by VacayGreen or issued to Users by Client.
                        Client shall restrict its Users from sharing passwords.
                        Client agrees to immediately notify VacayGreen of any
                        unauthorized use of any account or login and password
                        issued to Clients Users, or any other breach of security
                        known to Client. VacayGreen shall have no liability for
                        any loss or damage arising from Client’s failure to
                        comply with the terms set forth in this Section 4.1. 4.2 No Circumvention of Security. Neither Client nor
                        any User may circumvent or otherwise interfere with any
                        user authentication or security of the Services. Client
                        will immediately notify VacayGreen of any breach, or
                        attempted breach, of security known to Client. 4.3
                        Security. VacayGreen will use commercially reasonable
                        efforts to maintain appropriate administrative, physical
                        and technical safeguards for protection of the security,
                        confidentiality and integrity of Client Data, except as
                        required by law. Notwithstanding the foregoing, Client
                        acknowledges that, notwithstanding any security
                        precautions deployed by VacayGreen, the use of, or
                        connection to, the Internet provides the opportunity for
                        unauthorized third parties to circumvent such
                        precautions and illegally gain access to the Services
                        and Client Data. VacayGreen cannot and does not guaranty
                        the privacy, security, integrity or authenticity of any
                        information transmitted over or stored in any system
                        connected to or accessible via the Internet or otherwise
                        or that any such security precautions will be adequate
                        or sufficient.
                      </p>
                      <p>
                        5. CLIENT OBLIGATIONS. Client shall be solely
                        responsible for its actions and the actions of its Users
                        while using the Services. Client acknowledges and
                        agrees: (a) to abide by all local, state, national, and
                        international laws, statutes, rules and regulations
                        applicable to Client’s use of the Services, including
                        without limitation the provision and storage of Client
                        Data, (b) not to send or store data on or to the
                        Services which violates the rights of any individual or
                        entity established in any jurisdiction, (c) not to
                        upload in any way any information or content that
                        contain Malicious Code or data that may damage the
                        operation of the Services or another’s computer or
                        mobile device, (d) not to upload in any way any data
                        regarding an individual’s financial or economic
                        identity, sexual orientation, religious beliefs, medical
                        or physical identity, including any information
                        comprised of either “Protected Health Information”
                        subject to and defined by the Health Insurance
                        Portability and Accountability Act, or an individual’s
                        first name and last name, or first initial and last
                        name, in combination with any one or more of the
                        following data elements that relate to such individual:
                        Social Security number, driver’s license number or
                        state¬ issued identification card number, financial
                        account number, or credit or debit card number, with or
                        without any required security code, access code,
                        personal identification number or password, that would
                        permit access to an individual’s financial account, (e)
                        not to use the Services for illegal, fraudulent,
                        unethical or inappropriate purposes, (f) not to
                        interfere or disrupt networks connected to the Services
                        or interfere with other ability to access or use the
                        Services, (g) not to distribute, promote or transmit
                        through the Services any unlawful, harmful, obscene,
                        pornographic or otherwise objectionable material of any
                        kind or nature, (h) not to transmit or post any material
                        that encourages conduct that could constitute a criminal
                        offense or give rise to civil liability, (i) not to
                        interfere with another customer’s use and enjoyment of
                        the Services or another person or entity’s use and
                        enjoyment of similar services, (j) not to use the
                        Services in any manner that impairs the Services,
                        including without limitation the servers and networks on
                        which the Services are provided, (k) to comply with all
                        regulations, policies and procedures of networks
                        connected to the Service and VacayGreen’s service
                        providers, and (l) to use the Services only in
                        accordance with the Documentation. Client acknowledges
                        and agrees that VacayGreen neither endorses the contents
                        of any Client communications, Client Data, or Other
                        Information nor assumes any responsibility for any
                        offensive material contained therein, any infringement
                        of third party Intellectual Property Rights arising
                        therefrom or any crime facilitated thereby. VacayGreen
                        may remove any violating content posted or stored using
                        the Services or transmitted through the Services,
                        without notice to Client. Notwithstanding the foregoing,
                        VacayGreen does not guarantee, and does not and is not
                        obligated to verify, authenticate, monitor or edit
                        Client Data, Other Information, or any other information
                        or data input into or stored in the Services for
                        completeness, integrity, quality, accuracy or otherwise.
                        Client shall be responsible and liable for the
                        completeness, integrity, quality and accuracy of Client
                        Data and Other Information input into the Services.
                        VacayGreen reserves the right to amend, alter, or modify
                        Client’s conduct requirements as set forth in these
                        Terms at any time. VacayGreen may deliver notice of such
                        updated requirements to Client via e-mail or through the
                        Services. Client’s continued access to and use of the
                        Services following issuance of such updated Client
                        requirements shall constitute Client’s acceptance
                        thereof. 5.4 Accuracy of Client’s Contact Information;
                        Email Notices. Client agrees to provide accurate,
                        current and complete information as necessary for
                        VacayGreen to communicate with Client from time to time
                        regarding the Services, issue invoices or accept
                        payment, or contact Client for other account-related
                        purposes. Client agrees to keep any online account
                        information current and inform VacayGreen of any changes
                        in Client’s legal business name, address, email address
                        and phone number. Client agrees to accept emails from
                        VacayGreen at the e-mail addresses specified by its
                        Users for login purposes. In addition, Client agrees
                        that VacayGreen may rely and act on all information and
                        instructions provided to VacayGreen by Users from the
                        above-specified e-mail address. 5.5 Temporary
                        Suspension. VacayGreen may temporarily suspend Client’s
                        and its Users’ access to the Services in the event that
                        either Client any of their Users is engaged in, or
                        VacayGreen in good faith suspects Client or any of its
                        Users is engaged in, any unauthorized conduct
                        (including, but not limited to any violation of this
                        Agreement). VacayGreen will attempt to contact Client
                        prior to or contemporaneously with such suspension;
                        provided, however, that VacayGreen’s exercise of the
                        suspension rights herein shall not be conditioned upon
                        Client’s receipt of any notification. A suspension may
                        take effect for Client’s entire account and Client
                        understands that such suspension would therefore include
                        User sub-accounts. Client agrees that VacayGreen shall
                        not be liable to Client, any of its Users, or any other
                        third party if VacayGreen exercises its suspension
                        rights as permitted by this Section 5.5. Upon determining that Client has ceased the
                        unauthorized conduct leading to the temporary suspension
                        to VacayGreen’s reasonable satisfaction, VacayGreen
                        shall reinstate Client’s and their respective Users’
                        access and use of the Services. Notwithstanding anything
                        in this Section 5.5 to the contrary, VacayGreen’s
                        suspension of access to the Services is in addition to
                        any other remedies that VacayGreen may have under these
                        Terms or otherwise, including but not limited to
                        termination of these Terms for cause. Additionally, if
                        there are repeated incidences of suspension, regardless
                        of the same or different cause and even if the cause or
                        conduct is ultimately cured or corrected, VacayGreen may
                        , in its reasonable discretion, determine that such
                        circumstances, taken together, constitute a material
                        breach. 5.6 Evaluations. At VacayGreen’s request, Client
                        will provide VacayGreen with written or oral report(s)
                        of the results of Client’s evaluation of the Services,
                        including, but not limited to, a report of any errors
                        that Client has discovered in the Services. 5.7 Client’s
                        Customers. Client shall reasonably promote, to its
                        customers and occupants and prospective occupants of
                        Properties, the use of Carbon Offsets and inform such
                        customers (and occupants and prospective occupants) that
                        VacayGreen may contact them to offer Carbon Offsets for
                        personal use. Client represents and warrants to
                        VacayGreen that the sharing with VacayGreen of any and
                        all Client Data does not violate or breach any contract,
                        license, agreement, statute, regulation, law or order.{" "}
                      </p>
                      <p>
                        6. AVAILABILITY. Subject to the terms and conditions of
                        these Terms, VacayGreen will use commercially reasonable
                        efforts to make the Services available with minimal
                        downtime 24 hours a day, 7 days a week; provided,
                        however, that the following are excepted from
                        availability commitments: (a) planned downtime (with
                        regard to which VacayGreen will use commercially
                        reasonable efforts to provide at least 24 hours advance
                        notice and routine maintenance times as otherwise
                        specified by VacayGreen, or (b) any unavailability
                        caused by circumstances beyond VacayGreen’s reasonable
                        control, including without limitation, acts of God, acts
                        of government, flood, fire, earthquakes, civil unrest,
                        acts of terror, strikes or other labor problems,
                        Internet service provider failures or delays, or the
                        unavailability or modification by third parties of Third
                        Party Offerings. Certain enhancements to the Services
                        made generally available at no cost to all subscribing
                        customers during the applicable Subscription Term will
                        be made available to Client at no additional charge.
                        However, the availability of some new enhancements to
                        the Services may require the payment of additional fees,
                        and VacayGreen will determine at its sole discretion
                        whether access to any other such new enhancements will
                        require an additional fee. Unless VacayGreen requires
                        Client to agree to additional terms in connection with
                        new features or modules, these Terms will apply to, and
                        the Services includes, any enhancements, updates,
                        upgrades and new modules to the Services subsequently
                        provided by VacayGreen to Client hereunder.{" "}
                      </p>
                      <p>
                        7. FEES AND PAYMENT. 7.1 VacayGreen will provide Client
                        estimated monthly fees based on portfolio as of the last
                        day of the preceding month. Actual fees will be based on
                        actual property data over the course of the month. In
                        the event the Subscription Term shall expire or
                        terminate on any date other than the first day of a
                        calendar month, then a prorated fees shall be due upon
                        such expiration or termination. Each such payment shall
                        be due in full on each such monthly date throughout the
                        Subscription Term (or upon expiration or termination
                        thereof as provided above), shall be non-refundable and,
                        except as set forth above in this Subsection 7.2, shall
                        not be subject to proration or refund for any reason.
                        7.3 Transaction Fees. VacayGreen shall be paid, and
                        VacayGreen shall retain, Transaction Fees out of any and
                        all Shared Revenue as and when Shared Revenue is
                        received by VacayGreen. Transaction Fees shall be
                        fully-earned upon receipt of Shared Revenue by
                        VacayGreen and shall not be subject to any credit,
                        proration or refund. The balance of any Shared Revenue,
                        after receipt by VacayGreen of the Transaction Fees,
                        shall be remitted to Client on or before the fifteenth
                        (15th) day of the calendar month following the month
                        during which such Shared Revenue was received by
                        VacayGreen. Client shall deliver a current W-9 to
                        VacayGreen at all times, and VacayGreen shall report
                        income to Client on IRS form 1099 for the portion of all
                        Shared Revenue paid to Client. VacayGreen may setoff
                        against Shared Revenue for any past due Fees, Refunds,
                        or any other amounts due or payable to VacayGreen under
                        this Agreement. 7.4 Taxes, Shipment, etc. Unless
                        otherwise specified in writing by VacayGreen, all prices
                        are F.O.B. Destination (Delivery Duty Unpaid), provided
                        that shipment to any destination outside the Continental
                        United States shall be F.O.B Shipping Point (Delivery
                        Duty Unpaid). All prices are subject to any additions
                        that may be necessary to cover any tax or charge now
                        existing or hereafter imposed by any federal, state,
                        municipal, regional, international or other governmental
                        or other authority upon products or services herein
                        described, or upon any feature of this transaction,
                        including but not limited to, sales tax, customs or
                        duties, and value added tax (and any other similar or
                        equivalent taxes, duties, fees and levies). All prices
                        are in United States Dollars, and all amounts under this
                        Agreement are payable in United States Dollars only.
                        VacayGreen shall attempt to meet any completion and/or
                        shipment date specified, but in no event shall
                        VacayGreen otherwise be responsible or liable for
                        failure to produce, ship or deliver by such date, and
                        time shall not be of the essence in respect thereto; nor
                        shall VacayGreen be liable to Client or any third party
                        for indirect or consequential damages due to delays in
                        the production, completion, shipment or delivery of
                        goods whether or not due to causes within VacayGreen’s
                        control. 7.5 Price Changes. The pricing under each
                        Purchase Order during the term shall be the same as that
                        stated on the Purchase Order unless VacayGreen has given
                        Client written notice of a pricing increase at least
                        fifteen (15) days prior to such change, in which case
                        the pricing increase shall be effective upon the
                        expiration of such fifteen (15) day period. VacayGreen
                        may increase pricing quarterly without notification.{" "}
                      </p>
                      <p>
                        8. REPRESENTATIONS AND WARRANTIES; DISCLAIMER. 8.1
                        Mutual Representations and Warranties. Each party
                        represents, warrants and covenants that: (a) it has the
                        full power and authority to enter into these Terms and
                        to perform its obligations hereunder, without the need
                        for any consents, approvals or immunities not yet
                        obtained (and, as to Client, such warranty includes
                        Client’s authority, license and permission, from the
                        Property owner or otherwise, to use the Services at each
                        Property); and (b) its acceptance of and performance
                        under these Terms shall not breach any oral or written
                        agreement with any third party or any obligation owed by
                        it to any third party to keep any information or
                        materials in confidence or in trust. 8.2 Disclaimer of
                        Warranties. THE WARRANTIES SET FORTH IN THIS SECTION 8,
                        THE SERVICE, SOFTWARE AND THIRD-PARTY OFFERINGS ARE
                        PROVIDED ON AN AS-IS BASIS. CLIENT’S USE OF THE SERVICE
                        AND THIRD-PARTY OFFERINGS IS AT ITS OWN RISK. VacayGreen
                        DOES NOT MAKE, AND HEREBY DISCLAIMS, ANY AND ALL OTHER
                        EXPRESS, STATUTORY AND IMPLIED WARRANTIES, INCLUDING,
                        BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY,
                        FITNESS FOR A PARTICULAR PURPOSE, NONINFRINGEMENT AND
                        TITLE, QUALITY, SUITABILITY, OPERABILITY, CONDITION,
                        SYSTEM INTEGRATION, NON-INTERFERENCE, WORKMANSHIP,
                        TRUTH, ACCURACY (OF DATA OR ANY OTHER INFORMATION OR
                        CONTENT), ABSENCE OF DEFECTS, WHETHER LATENT OR PATENT,
                        AND ANY WARRANTIES ARISING FROM A COURSE OF DEALING,
                        USAGE, OR TRADE PRACTICE (COLLECTIVELY AND INDIVIDUALLY,
                        “ANY AND ALL WARRANTIES”). ANY WARRANTIES MADE BY
                        VACAYGREEN ARE FOR THE BENEFIT OF CLIENT ONLY AND NOT
                        FOR THE BENEFIT OF ANY THIRD PARTY. ANY MATERIALS
                        PROVIDED THROUGH THE SERVICE ARE LICENSED AND NOT SOLD.
                        VACAYGREEN DOES NOT WARRANT THAT: (A) THE USE OF THE
                        SERVICE OR SOFTWARE WILL BE SECURE, TIMELY,
                        UNINTERRUPTED OR ERROR-FREE OR OPERATE IN COMBINATION
                        WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM OR DATA; (B)
                        THE SERVICE WILL MEET CLIENT’S REQUIREMENTS OR
                        EXPECTATIONS; (C) ANY STORED DATA WILL BE ACCURATE OR
                        RELIABLE; (D) THE QUALITY OF ANY INFORMATION OR OTHER
                        MATERIAL OBTAINED BY CLIENT THROUGH THE SERVICE WILL
                        MEET CLIENT’S REQUIREMENTS OR EXPECTATIONS; (E) THE
                        SERVICE WILL BE ERROR-FREE OR THAT ERRORS OR DEFECTS IN
                        THE SERVICE WILL BE CORRECTED; OR (F) THE SERVER(S) THAT
                        MAKE THE SERVICE OR SOFTWARE AVAILABLE ARE FREE OF
                        VIRUSES OR OTHER HARMFUL COMPONENTS. THE SERVICE AND
                        SOFTWARE MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND
                        OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND
                        ELECTRONIC COMMUNICATIONS. VACAYGREEN IS NOT RESPONSIBLE
                        FOR ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGES
                        RESULTING FROM ANY OF THE FOREGOING. WITHOUT LIMITING
                        THE GENERALITY OF THE FOREGOING. CLIENT UNDERSTANDS AND
                        AGREES THAT PRODUCTS ARE MANUFACTURED BY A THIRD PARTY
                        MANUFACTURERS AND THAT THIRD PARTY OFFERINGS ARE
                        PROVIDED BY THIRD PARTIES, AND NEITHER OF THE FOREGOING
                        ARE MANUFACTURED, PROVIDED OR SERVICED BY VACAYGREEN.
                        VACAYGREEN DISCLAIMS ANY AND ALL RESPONSIBILITY OR
                        LIABILITY WITH RESPECT TO THIRD PARTY OFFERINGS AND
                        FURTHER DISCLAIMS ANY AND ALL WARRANTIES WITH RESPECT
                        THERETO.{" "}
                      </p>
                      <p>
                        9. INDEMNIFICATION AND INSURANCE. Client shall defend
                        VacayGreen and its officers, directors, members,
                        managers, representatives and employees (“VacayGreen
                        Indemnified Parties”) from and against any and all
                        claims, suits, proceedings, liabilities, damages, costs
                        (including attorneys’ fees) and losses directly or
                        indirectly arising from, caused by or relating to: (a)
                        Client’s use of the Services or the Software or
                        Hardware, except if and only to the extent caused by
                        VacayGreen’s willful misconduct, (b) the use by any
                        party, person or entity of any VacayGreen hardware
                        including, without limitation, any accident, damage,
                        loss, injury or death directly or indirectly arising
                        from, caused by or relating thereto, (c) the use by the
                        Client or any other party, person or entity of the Third
                        Party Offerings, (d) a claim or threat that Client Data,
                        the Client System or any Third Party Offerings (or the
                        exercise by VacayGreen of the rights granted herein with
                        respect thereto) infringes, misappropriates or violates
                        any third party’s Intellectual Property Rights or (e)
                        Client’s breach of these Terms or a Purchase Order or
                        the use or alleged use of the Services or Software other
                        than as permitted under this Agreement. Client shall
                        acquire and maintain insurance, as reasonably requested
                        by VacayGreen from time to time, covering VacayGreen and
                        the VacayGreen Indemnified Parties and naming VacayGreen
                        as an additional insured. Promptly upon any such request
                        by VacayGreen, Client shall provide VacayGreen with
                        certificates of insurance evidencing such insurance
                        coverage, and each certificate shall indicate that the
                        coverage represented thereby shall not be canceled,
                        terminated, materially changed or not be renewed until
                        at least 30 days prior written notice has been given to
                        VacayGreen and Client.
                      </p>
                      <p>
                        10. CONFIDENTIALITY. Client will not disclose any pricing
                        terms (“Confidential Information”) without VacayGreen’s
                        prior written approval. 1
                      </p>
                      <p>
                        1. PRIORITY RIGHTS. 11.1 Services. As between VacayGreen
                        and Client, all right, title and interest in the
                        Services, Software and any other VacayGreen materials
                        furnished or made available hereunder, and all
                        modifications and enhancements thereof, and all
                        suggestions, ideas and feedback proposed by Client
                        regarding the VacayGreen, including all copyright
                        rights, patent rights and other Intellectual Property
                        Rights in each of the foregoing, belong to and are
                        retained solely by VacayGreen or VacayGreen’s licensors
                        and providers, as applicable. Client hereby does and
                        will irrevocably assign to VacayGreen all evaluations,
                        ideas, feedback and suggestions made by Client to
                        VacayGreen regarding the Services (collectively,
                        “Feedback”) and all Intellectual Property Rights in the
                        Feedback. 11.2 Client Data. As between VacayGreen and
                        Client, all right, title and interest in (a) Client
                        Data, (b) other information input into the Services by
                        Client (collectively, “Other Information”) and (c) all
                        Intellectual Property Rights in each of the foregoing,
                        belong to and are retained solely by Client. Client
                        hereby grants to VacayGreen a limited, non-exclusive,
                        royalty-free, worldwide license to use Client Data and
                        perform all acts with respect to Client Data as may be
                        necessary for VacayGreen to provide the Services to
                        Client, and a non¬exclusive, perpetual, irrevocable,
                        worldwide, royalty-free, fully paid license to use,
                        reproduce, modify and distribute the Other Information
                        as a part of the Aggregated Statistics (as defined in
                        Section 11.3 below). To the extent that receipt of
                        Client Data requires VacayGreen to utilize any account
                        information from a third party service provider, Client
                        shall be responsible for obtaining and providing
                        relevant account information and passwords, and
                        VacayGreen hereby agrees to access and use Client Data
                        solely for Client’s benefit and as set forth in these
                        Terms. As between VacayGreen and Client, Client is
                        solely responsible for the accuracy, quality, integrity,
                        legality, reliability, and appropriateness of all Client
                        Data. 11.3 Aggregated Statistics. Notwithstanding
                        anything else in these Terms or otherwise, VacayGreen
                        may monitor Client’s use of the Services and use data
                        and information related to such use, Client Data, and
                        Other Information in an aggregate and anonymous manner,
                        including to compile statistical and performance
                        information related to the provision and operation of
                        the Services (“Aggregated Statistics”). As between
                        VacayGreen and Client, all right, title and interest in
                        the Aggregated Statistics and all Intellectual Property
                        Rights therein, belong to and are retained solely by
                        VacayGreen. Client acknowledges that VacayGreen will be
                        compiling Aggregated Statistics based on Client Data,
                        Other Information, and information input by other
                        customers into the Services and Client agrees that
                        VacayGreen may (a) make such Aggregated Statistics
                        publicly available, and (b) use such information to the
                        extent and in the manner required by applicable law or
                        regulation and for purposes of data gathering, analysis,
                        service enhancement and marketing, provided that such
                        data and information does not identify Client or its
                        Confidential Information. 11.4 VacayGreen Developments.
                        As between VacayGreen and Client, VacayGreen is the sole
                        owner of the Software and the Documentation and shall
                        retain all Intellectual Property Rights therein subject
                        only Client’s right to use the Services as expressly
                        granted herein. All inventions, works of authorship and
                        developments conceived, created, written, or generated
                        by or on behalf of VacayGreen, whether solely or
                        jointly, and all Intellectual Property Rights therein,
                        shall be the sole and exclusive property of VacayGreen
                        (“Developments”). Client agrees that, except for Client
                        Confidential Information, to the extent that the
                        ownership of any contribution by Client or its employees
                        to the creation of the VacayGreen Developments is not,
                        by operation of law or otherwise, vested in VacayGreen,
                        Client hereby assigns and agrees to assign to VacayGreen
                        all right, title and interest in and to such VacayGreen
                        Developments, including without limitation all the
                        Intellectual Property Rights therein, without the
                        necessity of any further consideration. 11.5 Further
                        Assurances. To the extent any of the rights, title and
                        interest in and to Feedback or VacayGreen Developments
                        or Intellectual Property Rights therein cannot be
                        assigned by Client to VacayGreen, Client hereby grants
                        to VacayGreen an exclusive, royalty-free, transferable,
                        irrevocable, worldwide, fully paid-up license (with
                        rights to sublicense through multiple tiers of
                        sublicensees) to fully use, practice and exploit those
                        non-assignable rights, title and interest. To the extent
                        that the foregoing assignment and license are not
                        enforceable, Client agrees to waive and never assert
                        against VacayGreen those non-assignable and
                        non-licensable rights, title and interest. Client agrees
                        to execute any documents or take any actions as may
                        reasonably be necessary, or as VacayGreen may reasonably
                        request, to perfect ownership of the Feedback and
                        VacayGreen Developments. If Client is unable or
                        unwilling to execute any such document or take any such
                        action, VacayGreen may execute such document and take
                        such action on Client’s behalf as Client’s agent and
                        attorney-in-fact. The foregoing appointment is deemed a
                        power coupled with an interest and is irrevocable. 11.6
                        Carbon Offset Credits. To the extent any of the rights,
                        title and interest in and to Carbon Offset Credits
                        cannot be assigned by Client to VacayGreen, Client
                        hereby grants to VacayGreen an exclusive, royalty-free,
                        transferable, irrevocable, worldwide, fully paid-up
                        license (with rights to sublicense through multiple
                        tiers of sublicensees) to fully use, practice and
                        exploit those non-assignable rights, title and interest.
                        To the extent that the foregoing assignment and license
                        are not enforceable, Client agrees to waive and never
                        assert against VacayGreen those non-assignable and
                        non-licensable rights, title and interest. Client agrees
                        to execute any documents or take any actions as may
                        reasonably be necessary, or as VacayGreen may reasonably
                        request, to perfect ownership of the Carbon Offset
                        Credits. If Client is unable or unwilling to execute any
                        such document or take any such action, VacayGreen may
                        execute such document and take such action on Client’s
                        behalf as Client’s agent and attorney-in-fact. The
                        foregoing appointment is deemed a power coupled with an
                        interest and is irrevocable.
                      </p>
                      <p>
                        12. LIMITATION OF LIABILITY. 12.1 No Consequential
                        Damages. VacayGreen SHALL NOT BE LIABLE FOR ANY
                        INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE
                        DAMAGES, OR ANY DAMAGES FOR LOST DATA, BUSINESS
                        INTERRUPTION, LOST PROFITS, LOST REVENUE OR LOST
                        BUSINESS, ARISING OUT OF OR IN CONNECTION WITH THESE
                        TERMS, EVEN IF VacayGreen HAS BEEN ADVISED OF THE
                        POSSIBILITY OF SUCH DAMAGES, INCLUDING WITHOUT
                        LIMITATION, ANY SUCH DAMAGES ARISING OUT OF THE
                        LICENSING, PROVISION OR USE OF THE SERVICE OR THE
                        RESULTS THEREOF. VacayGreen WILL NOT BE LIABLE FOR THE
                        COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICE. 12.2
                        Limits on Liability. VacayGreen SHALL NOT BE LIABLE FOR
                        CUMULATIVE, AGGREGATE DAMAGES GREATER THAN AN AMOUNT
                        EQUAL TO THE AMOUNTS PAID BY CLIENT TO VacayGreen UNDER
                        THIS AGREEMENT DURING THE PERIOD OF SIX (6) MONTHS
                        PRECEDING THE DATE ON WHICH THE CLAIM FIRST ACCRUED
                        WITHOUT REGARD TO WHETHER SUCH CLAIM IS BASED IN
                        CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY
                        OR OTHERWISE. 12.3 Essential Purpose. CLIENT
                        ACKNOWLEDGES THAT THE TERMS IN THIS SECTION 12
                        (LIMITATION OF LIABILITY) SHALL APPLY TO THE MAXIMUM
                        EXTENT PERMITTED BY APPLICABLE LAW AND SHALL APPLY EVEN
                        IF AN EXCLUSIVE OR LIMITED REMEDY STATED HEREIN FAILS OF
                        ITS ESSENTIAL PURPOSE.
                      </p>
                      <p>
                        13. TERMINATION. 13.1 Each Subscription Term and this
                        Agreement may be terminated as follows: 13.1.1
                        VacayGreen may terminate this Agreement immediately by
                        written notice to Customer (“Termination for
                        Nonpayment”), in the event that Customer fails to timely
                        and in full pay any Fees or any other amount under these
                        Terms or any Purchase Agreement (“Client Payment
                        Default”) and fails to fully cure such Client Payment
                        Default within a period of five (5) days after such
                        payment was first due (provided that Client shall have
                        no right to cure more than two (2) Client Payment
                        Defaults during any six (6) month period); 13.1.2 either
                        party may terminate this Agreement immediately by
                        written notice to the other party (“Termination for
                        Cause”) in the event such other party (a) materially
                        breaches this Agreement (other than a Client Payment
                        Default, which shall be governed by clause (i) above)
                        and fails to cure such within a period of sixty (60)
                        days, after written notice thereof from the terminating
                        party, or such longer period (not exceeding one hundred
                        and twenty (120) days after such written notice) as is
                        reasonably necessary for such other party to effectuate
                        such cure and provided such other party uses its
                        reasonable, continual efforts to implement such cure at
                        all times or (b) files a petition for bankruptcy or has
                        a petition for bankruptcy filed against it that is not
                        dismissed within sixty (60) days after filing or admits
                        its inability to pay its debts as they mature, makes an
                        assignment for the benefit of its creditors or ceases to
                        function as a going concern or to conduct its operations
                        in the normal course of business; 13.1.3 either party
                        may terminate the applicable Subscription Terms upon
                        written notice given of no less than sixty (60) days
                        prior to the Anniversary date such termination and
                        setting forth the specific Properties (including
                        address) as to which such termination is applicable
                        (“Termination for Convenience”); and 13.1.4 VacayGreen
                        may terminate this Agreement and the Subscription Terms
                        immediately if and upon such time that the provider of
                        any of the Third Party Offerings ceases to make any such
                        Third Party Offerings available for integration with the
                        corresponding Services and Software features on terms
                        reasonably satisfactory to VacayGreen. A termination
                        under this Subsection 13.1.4 shall also be deemed a
                        Termination for Convenience by VacayGreen. 13.2
                        Non-Exclusive Remedy. The right of VacayGreen to effect
                        a Termination for Nonpayment or for Cause is without
                        prejudice to any other available remedies of VacayGreen.
                        Upon a Termination for Nonpayment or for Cause by
                        VacayGreen, Client shall nonetheless remain fully
                        obligated to pay the Annual Fee and Monthly Fee for the
                        remainder of the applicable Subscription Terms, as if
                        such termination had not occurred, and all such amounts
                        shall thereupon be accelerated and immediately due in
                        full without discount. It is expressly understood and
                        agreed that the provisions of the immediately preceding
                        sentence are not punitive, special, consequential,
                        indirect or excessive but intended to compensate
                        VacayGreen for benefit of the bargain struck by the
                        parties under this Agreement. 13.3 Termination Fee. In
                        the event Client shall effect a Termination for
                        Convenience, Client shall (a) immediately pay a
                        termination fee to VacayGreen equal to $250 (b) not be
                        entitled to any refund of any Fees or any other amounts
                        paid or due (c) remain fully liable to VacayGreen for
                        any and all defaults or breaches of this Agreement by
                        Client occurring or existing at any time on or before
                        the effective date of such Convenience Termination. 13.4
                        Effects of Termination. In the event of a Termination
                        for Nonpayment or a Termination for Cause by VacayGreen,
                        (a) Client’s use of and access to the Services and
                        Software shall cease and VacayGreen shall have the right
                        to immediately disable Services and the Software, (b)
                        all Purchase Orders shall terminate and (c) all fees and
                        other amounts owed to VacayGreen shall be immediately
                        due and payable by Client. VacayGreen shall have no
                        obligation to maintain or provide any Client Data and
                        may thereafter, unless legally prohibited, delete all
                        Client Data in its systems or otherwise in its
                        possession or under its control. In the event of a
                        Termination for Convenience then, the foregoing
                        provisions shall apply but be limited solely to the
                        Properties covered by such Termination for Convenience.
                        13.5 Survival. The respective rights and obligations of
                        VacayGreen and Client which by their nature would
                        continue beyond the termination or expiration of these
                        Terms shall survive the termination or expiration of
                        these Terms, including, without limitation, the rights
                        and obligations regarding payment, ownership,
                        confidentiality, limitation of liability, remedies, and
                        indemnification. Without limiting the generality of the
                        foregoing, this Section and Sections 1, 2.3, 2.4, 3.2,
                        3.3, 4.2, 7, 8, 9, 10, 11, 12, 13.4, 14 shall survive
                        any termination or expiration of these Terms. 
                      </p>
                      <p>
                        14. MISCELLANEOUS. 14.1 Notices. VacayGreen may give
                        notice to Client by means of a general notice through
                        the Services interface, electronic mail to Client’s
                        e-mail address on record with VacayGreen, or by written
                        communication sent by first class postage prepaid mail
                        or nationally recognized overnight delivery service to
                        Client’s address on record with VacayGreen. Client may
                        give notice to VacayGreen by electronic email at
                        VacayGreen’s email address or by written communication
                        sent by first class postage prepaid mail or nationally
                        recognized overnight delivery service addressed to
                        VacayGreen, Attention: contracts@VacayGreen.com. Notice
                        shall be deemed to have been given upon receipt or, if
                        earlier, two (2) business day safter mailing, as
                        applicable. All communications and notices to be made or
                        given pursuant to these Terms shall be in the English
                        language. 14.2 Governing Law. These Terms and the rights
                        and obligations of the parties to and under this
                        agreement shall be governed by and construed under the
                        laws of the United States and the State of Delaware, as
                        applied to agreements entered into and to be performed
                        in such State without giving effect to conflicts of laws
                        rules or principles. The parties agree that the United
                        Nations Convention on Contracts for the International
                        Sale of Goods is specifically excluded from application
                        to these Terms. Any dispute arising out of or in
                        connection with these Terms, including but not limited
                        to any question regarding its existence, interpretation,
                        validity, performance, or termination, or any dispute
                        between the parties arising from the parties’
                        relationship created by these Terms, shall be referred
                        to and finally resolved by arbitration administered by
                        the American Arbitration Association under its rules.
                        The number of arbitrators shall be one (1). The parties
                        shall endeavor to agree upon the sole arbitrator and
                        jointly nominate the arbitrator. If the parties cannot
                        agree upon the sole arbitrator within a time prescribed
                        by AAA, the parties shall request the AAA to propose
                        five (5) arbitrators and each party shall rank the
                        proposed arbitrators. The AAA shall appoint an
                        arbitrator from the list of five (5), based upon the
                        parties’ rankings. The seat, or legal place of
                        arbitration shall be Delaware, United States.
                        Notwithstanding the foregoing, VacayGreen has the right
                        to pursue equitable relief in the state and federal
                        courts located in Delaware, and Client agrees to the
                        exclusive jurisdiction and venue of such courts. 14.3
                        Publicity. VacayGreen has the right to reference and use
                        Client’s name and trademarks and disclose the nature of
                        the Services provided hereunder in each case in
                        VacayGreen business development and marketing efforts,
                        including without limitation VacayGreen’s web site. 14.4
                        No Solicitation of Employees. Client agrees that, so
                        long as the Subscription Term remains in effect, and for
                        a period of two (2) year following the termination or
                        expiration of the Subscription Term, it will not
                        directly solicit for employment the employees of
                        VacayGreen without VacayGreen’s prior written consent;
                        provided, however, that the foregoing prohibition shall
                        not preclude the hiring by Client of any individual who
                        responds to a general solicitation or advertisement,
                        whether in print or electronic form, only job postings
                        and social networking sites. 14.5 Export. The Services
                        utilize software and technology that may be subject to
                        United States and foreign export controls. Client
                        acknowledges and agrees that the Services shall not be
                        used, and none of the underlying information, software,
                        or technology may be transferred or otherwise exported
                        or re-exported to countries as to which the United
                        States maintains an embargo (collectively, “Embargoed
                        Countries”), or to or by a national or resident thereof,
                        or any person or entity on the U.S. Department of
                        Treasury’s List of Specially Designated Nationals or the
                        U.S. Department of Commerce’s Table of Denial Orders
                        (collectively, “Designated Nationals”). The lists of
                        Embargoed Countries and Designated Nationals are subject
                        to change without notice. By using the Services, Client
                        represents and warrants that it is not located in, under
                        the control of, or a national or resident of an
                        Embargoed Country or Designated National. The Services
                        may use encryption technology that is subject to
                        licensing requirements under the U.S. Export
                        Administration Regulations, 15 C.F.R. Parts 730-774 and
                        Council Regulation (EC) No. 1334/2000. Client agrees to comply strictly with all applicable
                        export laws and assume sole responsibility for obtaining
                        licenses to export or re-export as may be required.
                        VacayGreen and its licensors make no representation that
                        the Services is appropriate or available for use in
                        other locations. Any diversion of Client Data contrary
                        to law is prohibited. None of Client Data, nor any
                        information acquired through the use of the Services, is
                        or will be used for nuclear activities, chemical or
                        biological weapons, or missile projects. 14.6 General.
                        Client shall not assign its rights hereunder, or
                        delegate the performance of any of its duties or
                        obligations hereunder, whether by merger, acquisition,
                        sale of assets, operation of law, or otherwise, without
                        the prior written consent of VacayGreen. Any purported
                        assignment in violation of the preceding sentence is
                        null and void. Subject to the foregoing, this Agreement
                        shall be binding upon, and inure to the benefit of, the
                        successors and assigns of the parties thereto. Except as
                        otherwise specified in these Terms, these Terms may be
                        amended or supplemented only by a writing that refers
                        explicitly to these Terms and that is signed on behalf
                        of both parties. No waiver will be implied from conduct
                        or failure to enforce rights. No waiver will be
                        effective unless in a writing signed on behalf of the
                        party against whom the waiver is asserted. If any of
                        these Terms is found invalid or unenforceable that term
                        will be enforced to the maximum extent permitted by law
                        and the remainder of the Terms will remain in full
                        force. The parties are independent contractors and
                        nothing contained herein shall be construed as creating
                        an agency, partnership, or other form of joint
                        enterprise between the parties. These Terms, including
                        all applicable Purchase Orders constitute the entire
                        agreement between the parties relating to this subject
                        matter and supersedes all prior or simultaneous
                        understandings, representations, discussions,
                        negotiations, and agreements, whether written or oral.
                        Except for Client’s payment obligations hereunder,
                        neither party shall be liable to the other party or any
                        third party for failure or delay in performing its
                        obligations under these Terms when such failure or delay
                        is due to any cause beyond the control of the party
                        concerned, including, without limitation, acts of God,
                        governmental orders or restrictions, fire, or flood,
                        provided that upon cessation of such events such party
                        shall thereupon promptly perform or complete the
                        performance of its obligations hereunder.
                      </p>
                    </TitleSection>
                  }
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </LayoutOne>
    </Fragment>
  );
};

export default Terms;
