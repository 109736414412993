import React, { Fragment } from "react";
import { Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";
import TitleSection from "../../components/Titlesection/TitleSection";
import PageTitle from "../../components/utility";
import { LayoutOne } from "../../layouts";

import classnames from "classnames";

const Info = () => {
  return (
    <Fragment>
      <PageTitle
        PageTitle="VacayGreen"
        favicon="assets/images/logo/vg-icon-sq.png"
      />
      <LayoutOne activeClass="active" wrapperClass="home-page-1">
        <main>
          <Container className="mt-100">
            <Row className="justify-content-center">
              <Col sm="12" lg="6" md="8">
                {
                  <TitleSection spacer="mb-100" textAlign="text-center">
                    <h2 className="title-text mb-30">How are Carbon Offsets Produced?</h2>
                  </TitleSection>
                }
              </Col>
            </Row>

            <div className="features-nav ul-li-center mb-100">
              {/* class="nav" */}
              <Row>
                <Col md={4} className="text-center">
                    <span className="item-icon">
                      <img
                        src="assets/images/info/capture.svg"
                        alt="icon_not_found"
                        style={{height:100}}
                        className="mb-4"
                      />
                    </span>
                    <h4 style={{color:'#6C9807'}}>Capture and Destroy</h4>
                    <p>This prevents GHG from being released into the environment, ie landfill methane gas capture.</p>
                </Col>
                <Col md={4} className="text-center">
                    <span className="item-icon">
                      <img
                        src="assets/images/info/clean.svg"
                        alt="icon_not_found"
                        style={{height:100}}
                        className="mb-4"
                      />
                    </span>
                    <h4 style={{color:'#6C9807'}}>Clean Energy Production</h4>
                    <p>This eliminates the need to produce energy from fossil fuels, ie wind and solar energy production. </p>
                </Col>
                <Col md={4} className="text-center">
                    <span className="item-icon">
                      <img
                        src="assets/images/info/store.svg"
                        alt="icon_not_found"
                        style={{height:100}}
                        className="mb-4"
                      />
                    </span>
                    <h4 style={{color:'#6C9807'}}>Capture and Store</h4>
                    <p>This prevents the release of GHG into the atmosphere, ie reforestation projects.</p>
                </Col>
              </Row>
            </div>  
          </Container>
          <Container className="mt-100">
            <Row className="justify-content-center">
              <Col sm="12" lg="6" md="8">
                {
                  <TitleSection spacer="mb-100" textAlign="text-center">
                    <h2 className="title-text mb-30">Carbon Offset Certification</h2>
                    <p className="paragraph-text">Vacay Green only purchases carbon offset credits that are certified by: Gold Standard, Verified Carbon Standard, Climate Action Reserve (CAR), American Carbon Registry (ACR).
These accreditations ensure that the credits are quantifiable, permanent and independently verified. </p>
                  </TitleSection>
                }
              </Col>
            </Row>

            <div className="features-nav ul-li-center mb-100">
              {/* class="nav" */}
                <Row>
                  <Col md={4} className="text-center mb-60">
                    <span className="item-icon">
                      <img
                        src="assets/images/credits/Climate Action.svg"
                        alt="icon_not_found"
                        style={{height:200}}
                        className="mb-4"
                      />
                    </span>
                    
                  </Col>
                  <Col md={8} className="mb-60">
                      <h2>Climate Action Reserve (CAR)</h2>
                      <p className="paragraph-text">The Climate Action Reserve was launched in 2008. It is a USA based voluntary offsets program whose projects are implemented within North America.  The Climate Action Reserve (CAR) establishes standards for quantifying and verifying GHG emissions reduction projects, provides oversight to independent third-party verification bodies, and issues and tracks carbon credits, called Climate Reserve Tonnes (CRTs).</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="text-center mb-60">
                    <span className="item-icon">
                      <img
                        src="assets/images/credits/VCS.svg"
                        alt="icon_not_found"
                        style={{height:100}}
                        className="mb-4"
                      />
                    </span>
                    
                  </Col>
                  <Col md={8} className="mb-60">
                      <h2>Verified Carbon Standard (VCS)</h2>
                      <p className="paragraph-text">This standard was developed by the Climate Group and International Emissions Trading Association (IETA). It provides real, quantifiable, additional and permanent projects based emission reductions.</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="text-center mb-60">
                    <span className="item-icon">
                      <img
                        src="assets/images/credits/ACR.svg"
                        alt="icon_not_found"
                        style={{height:100}}
                        className="mb-4"
                      />
                    </span>
                    
                  </Col>
                  <Col md={8} className="mb-60">
                      <h2>American Carbon Registry (ACR)</h2>
                      <p className="paragraph-text">The American Carbon Registry (ACR), a nonprofit enterprise of Winrock International, was founded in 1996 as the first private voluntary greenhouse gas registry in the world.  Winrock operates ACR to create confidence in the environmental and scientific integrity of carbon offsets in order to accelerate transformational emission reduction actions.</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="text-center mb-60">
                    <span className="item-icon">
                      <img
                        src="assets/images/credits/Gold Standard.svg"
                        alt="icon_not_found"
                        style={{height:60}}
                        className="mb-4"
                      />
                    </span>
                    
                  </Col>
                  <Col md={8}>
                      <h2>Gold Standard Verification (GS)</h2>
                      <p className="paragraph-text">Launched in May 2006 by WWF-UK (a nonprofit foundation). It is a simplified version of the CDM Gold Standard, using the same basic methodologies. Only available for projects in developing countries. They are focused on renewable energy and energy efficient projects with strong sustainable development benefits. </p>
                  </Col>
                </Row>
            </div>  
            <p className="text-center">All credits issued from any of the major carbon standards (CAR, VCS, ACR, GS) will have undergone a robust verification process by an ISO accredited third-party verifier. These credits are also all tracked on registries to ensure emissions reductions are not double counted.</p>
          </Container>
          <Container className="mt-100">
            <Row className="justify-content-center">
              <Col sm="12" lg="10" md="8">
                {
                  <TitleSection spacer="mb-100" textAlign="text-center">
                    <h2 className="title-text mb-30">Carbon Offset vs Renewable Energy Credit (REC)</h2>
                    <p className="paragraph-text">Renewable Energy Certificates (RECs) and carbon offsets are both instruments that represent a quantifiable environmental benefit associated with an activity. A REC represents the environmental attributes derived from 1 megawatt-hour of renewable energy generation. A carbon offset represents the prevention or sequestration of 1 metric ton of carbon dioxide-equivalent emissions.
RECs are typically used for compliance.</p>
                  </TitleSection>
                }
              </Col>
            </Row>

            <div className="features-nav ul-li-center mb-100">
              {/* class="nav" */}
                <Row>
                  <Col md={6} className="px-5">
                    <p>
                    The original purpose of RECs was for tracking compliance with legislated electric utility renewable generation quotas (i.e., Renewable Portfolio Standards, or RPSs). The two major REC categories are:
                    </p>
                    <p>
                    <strong>RPS Compliance RECs:</strong>  These RECs can only be issued to renewable energy facilities that meet the eligibility requirements of a state or other jurisdictions RPS legislation.
                    </p>
                    <p>
                    <strong>Voluntary market RECs:</strong> Electricity consumers are typically advised that they can “buy” electricity from renewable energy generators, versus fossil fuel-fired generators, through purchasing voluntary market RECs. These voluntary RECs (and GOs) are supplied by wind farms or hydroelectric generators in jurisdictions where they are not eligible to be sold for RPS compliance because the REC market in that jurisdiction is oversupplied. These RECs are typically registered with an NGO program (e.g., Green-e) and may be registered with regional certificate tracking systems.
                    </p>
                  </Col>
                  <Col md={6} className="p-2">
                    <span className="item-icon">
                      <img
                        src="assets/images/info/windmill.svg"
                        alt="icon_not_found"
               
                        className="mb-4"
                      />
                    </span>  
                  </Col>
                </Row>
            </div>  
          </Container>
        </main>
      </LayoutOne>
    </Fragment>
  );
};

export default Info;
