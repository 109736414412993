import React, { Fragment } from "react";
import { Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";
import TitleSection from "../../components/Titlesection/TitleSection";
import PageTitle from "../../components/utility";
import { LayoutOne } from "../../layouts";

import classnames from "classnames";

const AboutUs = () => {
  return (
    <Fragment>
      <PageTitle
        PageTitle="VacayGreen"
        favicon="assets/images/logo/vg-icon-sq.png"
      />
      <LayoutOne activeClass="active" wrapperClass="about-section">
        <main className="about-content">
          <section className="bg-light-green">
            <Container className="mt-50">
              <Row>
                <Col sm="12" lg="6" md="6" className="mt-100">
                  {
                    <TitleSection spacer="mb-100">
                      <div className="big-title-text mb-30">About Us</div>
                      <div className="title-text mb-30">
                        We are making this planet greener one Vacation at a
                        time.
                      </div>
                      <p>
                        At VacayGreen, we’re helping make the world cleaner and
                        healthier, by making eco-friendly energy more accessible
                        to vacation renters. We do this by providing
                        cutting-edge software that integrates seamlessly with
                        leading property management platforms — facilitating
                        purchases of certified carbon offsets, and tailoring
                        carbon offset packages for individual properties. In all
                        these ways, we’re making this planet a better place to
                        live, with every single vacation stay.
                      </p>
                    </TitleSection>
                  }
                </Col>
                <Col sm="12" lg="6" md="6" className="mt-100">
                  {
                    <TitleSection spacer="mb-50" textAlign="text-center">
                      <img
                        src="/assets/images/about/bro.svg"
                        className="mb-5"
                      />
                    </TitleSection>
                  }
                </Col>
              </Row>
            </Container>
          </section>
          <section>
            <Container className="mt-50">
              <Row>
                <Col sm="12" lg="4" md="4" className="mt-100 text-center">
                  <div className="green-title-text mb-30">Our Purpose</div>
                  <p className="mb-30">
                    We’re bringing carbon neutrality to the last mile of
                    vacation travel, with innovative software that delivers
                    property-specific carbon offset recommendations with no
                    extra data entry — and even facilitates the purchase of
                    those offsets through leading property management platforms.
                  </p>
                </Col>
                <Col sm="12" lg="4" md="4" className="mt-100 text-center">
                  <div className="green-title-text mb-30">Our Promise</div>
                  <p className="mb-30">
                    We will continue to engineer and build technology that
                    reduces the carbon footprint of vacation stays. By
                    incentivizing and supporting carbon-neutral travel, we will
                    diligently and proactively work toward creating a
                    responsible and sustainable future for the vacation rental
                    industry.
                  </p>
                </Col>
                <Col sm="12" lg="4" md="4" className="mt-100 text-center">
                  <div className="green-title-text mb-30">Our Motivation</div>
                  <p className="mb-30">
                    We’re travelers ourselves, which means we love beautiful
                    natural environments, and want to do everything we can to
                    help preserve them for future generations. That’s why we’re
                    making carbon neutrality more accessible for travelers and
                    property managers everywhere.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="bg-light-green">
            <Container >
              <Row>
                <Col sm="12" lg="6" md="6">
                  {
                    <TitleSection textAlign="text-center">
                      <img height={100}
                        src="/assets/images/about/family.svg"
                        
                      />
                    </TitleSection>
                  }
                </Col>
                <Col sm="12" lg="6" md="6" style={{display:'flex', flexDirection: 'column', justifyContent: 'center'}}>
                  {
                    <TitleSection>
                      <div className="title-text mb-30">
                        VacayGreen Family
                      </div>
                      <p>
                        A few small carbon offsets might seem like small steps
                        toward saving the environment — but as part of the
                        VacayGreen Family, travelers and property managers are
                        helping multiply that carbon reduction across thousands
                        of locations and millions of vacation stays. On that
                        scale, VacayGreen really does have the power to help
                        make this planet cleaner and greener
                      </p>
                    </TitleSection>
                  }
                </Col>
              </Row>
            </Container>
          </section>
          <section>
            <Container className="mt-50">
              <Row>
                <Col sm="12" lg="6" md="6">
                  {
                    <TitleSection spacer="mb-100 text-left">
                      <div className="title-text mb-30">Our Mission</div>
                      <p>
                        We’re on a quest to make the world cleaner, greener and
                        healthier, by making it easier than ever for property
                        managers to find tailored carbon offsets — and making it
                        easier for guests to find vacation rental properties
                        that support their commitment to carbon neutrality.
                      </p>
                    </TitleSection>
                  }
                </Col>
                <Col sm="12" lg="6" md="6"> 
                  {
                    <TitleSection spacer="mb-50" textAlign="text-center">
                      <img
                        src="/assets/images/about/mission.svg"
                        className="mb-5"
                      />
                    </TitleSection>
                  }
                </Col>
              </Row>
              <Row>
                <Col sm="12" lg="6" md="6"> 
                  {
                    <TitleSection spacer="mb-50" textAlign="text-center">
                      <img
                        src="/assets/images/about/values.svg"
                        className="mb-5"
                      />
                    </TitleSection>
                  }
                </Col>
                <Col sm="12" lg="6" md="6" className="text-right">
                  {
                    <TitleSection spacer="mb-100">
                      <div className="title-text mb-30">Our Values</div>
                      <p>
                      This planet Earth is the only one we’ve got. At VacayGreen, we believe in taking responsible stewardship of this world’s natural environments — and that means taking active steps toward carbon neutrality, so we can be proud of the beautiful world we hand over to future generations.
                      </p>
                    </TitleSection>
                  }
                </Col>
                
              </Row>
              <Row>
                <Col sm="12" lg="6" md="6">
                  {
                    <TitleSection spacer="text-left">
                      <div className="title-text mb-30">Our Vision</div>
                      <p>
                      We’re working to build a future where vacation stays are 100% carbon neutral. Our industry-leading software makes it easier for property managers to offset their properties’ carbon footprints — attracting more eco-conscious guests to these properties, and bringing us closer to the greener future we envision.
                      </p>
                    </TitleSection>
                  }
                </Col>
                <Col sm="12" lg="6" md="6" > 
                  {
                    <TitleSection textAlign="text-center">
                      <img
                        src="/assets/images/about/vision.svg"
                        className="mb-5"
                      />
                    </TitleSection>
                  }
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </LayoutOne>
    </Fragment>
  );
};

export default AboutUs;
