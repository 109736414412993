import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import ContactForm from "../Formik";
import Contacttitle from "../../data/contact/contact-title.json";
import ContactData from "../../data/contact/contact.json";
const [{ title, subTitle }] = Contacttitle;
const Contact = ({ sectionSpace }) => {
  return (
    <Fragment>
      <section
        id="contact-section"
        className={`contact-section ${sectionSpace} clearfix mb-4 mt-5`}
      >
        <Container>
          <Row className="justify-content-center">
            <Col xs="12">
              <div className="section-title mb-4 text-center">
                <p className="mb-15"> Learn more on how <strong>VacayGreen</strong>  proprietary platform can automate creating carbon neutral vacation rental properties for professional property managers. </p>
                <h2 className="title-text mb-0">Contact Us!</h2>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-lg-between justify-content-md-center">
            <Col xs="12">
              <ContactForm></ContactForm>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default Contact;
