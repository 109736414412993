import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ScrollToTop } from "./components/ScrollToTop";
import { HomePageOne } from "./pages";
import { HomePageTwo } from "./pages";
import { HomePageThree } from "./pages";
import { HomePageFour } from "./pages";
import { HomePageFive } from "./pages";
import { HomePageSix } from "./pages";
import { HomePageSeven } from "./pages";
import { HomePageEight } from "./pages";
import { HomePageNine } from "./pages";
import { HomePageTen } from "./pages";
import { HomePageEleven } from "./pages";
import { HomePageTwelve } from "./pages";
import { FeaturePageOne } from "./pages";
import { FeaturePageTwo } from "./pages";
import { AboutPage } from "./pages";
import { ContactPage } from "./pages";
import { TeamPage } from "./pages";
import { FaqPage } from "./pages";
import { ErrorPage } from "./pages";
import { RegisterPage } from "./pages";
import { LogInPage } from "./pages";
import { ForGetPage } from "./pages";
import { ComingSoonPage } from "./pages";
import { AppDetailsV1Page } from "./pages";
import { AppDetailsV2Page } from "./pages";
import { ReviewPage } from "./pages";
import { PricingPage } from "./pages";
import { BlogDefaultPage } from "./pages";
import { BlogStandardtPage } from "./pages";
import { BlogTwoColumnPage } from "./pages";
import { BlogThreeColumnPage } from "./pages";
import { BlogDetailsOnePage } from "./pages";
import { BlogDetailsTwoPage } from "./pages";

import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/scss/bootstrap.scss";
import "react-modal-video/scss/modal-video.scss";
import "./assets/css/animate.css";
import "./assets/css/unicons.css";
import "./assets/css/flaticon.css";
import "./assets/css/fontawesome-all.css";
import "./assets/scss/style.scss";
import Info from "./pages/info/Info";
import AboutUs from "./pages/about-us/AboutUs";
import Privacy from "./pages/terms-and-conditions/Privacy";
import Terms from "./pages/terms-and-conditions/Terms";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/more-info" component={Info} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/privacy-policy" component={Privacy} />
          <Route path="/terms-and-conditions" component={Terms} />
          <Route path="/404" component={ErrorPage} />
          <Route exact path="/" component={HomePageOne} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
