import React from "react";
import { Link } from "react-router-dom";
import NewsLeter from "../NewsLetter/NewsLetter";

import { Container, Row, Col } from "reactstrap";
const Footer = ({ sectionSpace }) => {
  return (
    <footer
      id="footer-section"
      className={`footer-section ${sectionSpace} clearfix`}
      style={{ backgroundImage: `url(assets/images/footer-bg-3.png)`, marginTop:100 }}
    >
      <div
        className="footer-content mb-100 clearfix"
        data-aos="fade-up"
        data-aos-duration="450"
        data-aos-delay="200"
      >
        <Container>
          <Row>
            <Col lg="3" md="6">
              <div className="about-content">
                <div className="brand-logo mb-30">
                  <Link to="/" className="brand-link">
                    <img
                      src="assets/images/logo/logo-2.png"
                      alt="logo_not_found"
                      style={{height:90}}
                    />
                  </Link>
                </div>
                <p className="mb-30">
                VacayGreen provides industry-leading carbon offsets for vacation rentals.
                </p>
                {/*
                <div className="social-links ul-li clearfix">
                  <ul className="clearfix">
                    <li>
                      <Link to="/">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <i className="fab fa-linkedin-in"></i>
                      </Link>
                    </li>
                  </ul>
  </div>*/}
              </div>
            </Col>
            <Col lg="2" md="6">
            </Col>
            <Col lg="2" md="6">
              {/*<div className="useful-links ul-li-block clearfix">
                <h3 className="item-title">about us</h3>
                <ul className="clearfix">
                  <li>
                    <a href="mailto:info@vacaygreen.com">Contact us</a>
                  </li>
                  <li>
                    <Link to="/">Portfolio</Link>
                  </li>
                </ul>
              </div>
*/}
            </Col>
            <Col lg="2" md="6">
              <div className="useful-links ul-li-block clearfix">
                <h3 className="item-title">Apps</h3>
                <ul className="clearfix">
                  <li>
                    <Link to="/terms-and-conditions">Terms of Use</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </Col>

            
            {/*
            <Col lg="2" md="6">
              <div className="useful-links ul-li-block clearfix">
                <h3 className="item-title">service</h3>
                <ul className="clearfix">
                  <li>
                    <Link to="/">Pricing</Link>
                  </li>
                  <li>
                    <Link to="/">Support</Link>
                  </li>
                  <li>
                    <Link to="/">Testimonials</Link>
                  </li>
                  <li>
                    <Link to="/">Faq’s</Link>
                  </li>
                </ul>
              </div>
            </Col>
*/}
            <Col lg="3" md="6">
              <div className="contact-info ul-li-block clearfix">
                <h3 className="item-title">address</h3>
                <ul className="clearfix">
                  <li>
                    <a href="mailto:info@vacaygreen.com">
                      <small className="icon">
                        <i className="uil uil-envelope-minus"></i>
                      </small>
                      <span className="info-text text-lowercase">info@vacaygreen.com</span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.google.com/maps/place/2843+NW+Lolo+Dr,+Bend,+OR+97701,+USA/@44.0531942,-121.3650958,17z/data=!3m1!4b1!4m5!3m4!1s0x54b8c8129dc507fb:0xc91ec6886b9dfdf!8m2!3d44.0531904!4d-121.3629071">
                      <small className="icon">
                        <i className="uil uil-location-point"></i>
                      </small>
                      <span className="info-text">
                        2843 NW Lolo Drive, Bend, OR 97703
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <div className="footer-bottom clearfix">
          <Row>
            <Col md="6">
              <p className="copyright-text mb-0">
                Copyright @ {new Date().getFullYear()}{" "} <span style={{color:'#6C9807'}}>VacayGreen</span>. All rights reserved.
              </p>
            </Col>

            <Col md="6">
              <div className="useful-links ul-li-right clearfix">
                <ul className="clearfix">
                  <li>
                    <Link to="/terms-and-conditions">Terms of Use</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
