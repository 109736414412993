import React, { Fragment } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import BannerOne from "../../components/Banner/BannerOne";
import { BrandSlider } from "../../components/BrandSlider";
import Calculator from "../../components/Calculator/Calculator";
import Contact from "../../components/Contact/contact";
import { FaqSectionOne } from "../../components/FaqSection";
import { FeatureOne } from "../../components/Features";
import FeaturesNav from "../../components/FeaturesNav/FeaturesNav";
import { ServiceSliderOne } from "../../components/ServiceSlider";
import TitleSection from "../../components/Titlesection/TitleSection";
import PageTitle from "../../components/utility";
import { LayoutOne } from "../../layouts";

const HomePageOne = () => {
  return (
    <Fragment>
      <PageTitle
        PageTitle="VacayGreen"
        favicon="assets/images/logo/vg-icon-sq.png"
      />
      <LayoutOne activeClass="active" wrapperClass="home-page-1">
        <main>
          <BannerOne />
          <Row className="justify-content-center">
            <Col sm="12" lg="12" md="12">
              <TitleSection spacer="mt-5 mb-5" textAlign="text-center">
                <h2 className="title-text mb-30">What We Do</h2>
                <p className="paragraph-text mb-0 p-5" style={{fontSize:'1.2rem'}}>VacayGreen provides industry-leading carbon offsets for vacation rentals. <br/>Our propietary software deliver highly accurate, property specific offsets with no additional data entry.</p>
              </TitleSection>
            </Col>
          </Row>
          <Container className="px-5">
            <Row>
              <Col xs={12} sm={6} className="text-center p-5">
                <img src="/assets/images/home/Empowers Property.svg" className="mb-5"/>
                <p className="p-4">
                  <strong>VacayGreen</strong> empowers property managers to provide carbon neutral vacation properties for their guests.
                </p>
              </Col>
              <Col xs={12} sm={6} className="text-center p-5">
                <img src="/assets/images/home/Integration.svg" className="mb-5"/>
                <p className="p-4">
                  <strong>VacayGreen</strong> seamlessly facilitates the purchase of certified carbon offsets by integrating with all major PMS Platforms. 
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} className="text-center p-5">
                <img src="/assets/images/home/Calculations.svg" className="mb-1"/>
                <p className="p-4">
                  <strong>VacayGreen</strong> uses its proprietary calculator to determine property specific carbon footprint calculations and tailors a carbon offset package ideally suited for each individual Property Manager.
                </p>
              </Col>
              <Col xs={12} sm={6} className="text-center p-5">
                <img src="/assets/images/home/Girl on Vacation.svg" className="mb-1"/>
                <p className="p-4">
                  <strong>VacayGreen</strong> interfaces with each guest and provides a certificate documenting their contributions toward making their stay carbon neutral.
                </p>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} sm={12} lg="10" md="10">
                <Calculator></Calculator>
                <Contact></Contact>
              </Col>
            </Row>
          </Container>
          <FeatureOne sectionSpace="sec-ptb-160" />
          <Row className="justify-content-center p-3">
            <Col sm="10" lg="10" md="10">
              <TitleSection spacer="mt-5 mb-5" textAlign="text-center">
                <h2 className="title-text mb-30">Why do we care?</h2>
                <p className="paragraph-text mb-100 p5" style={{fontSize:'1.2rem'}}>The vacation rental industry is, by nature, a resource-intensive industry.<br/>The purchase of carbon offsets helps our industry mitigate its impact on the climate while we work towards a more carbon neutral society.</p>
                <p className="paragraph-text mb-0 p5">
                  <img src="/assets/images/home/Carbon Offset.svg"/> 
                </p>
              </TitleSection>
            </Col>
          </Row>
          <Row className="justify-content-center" style={{backgroundColor: '#F7FCF8'}}>
            <Col sm="10" lg="10" md="10">
              <TitleSection spacer="mt-5 mb-5" textAlign="text-center">
                <h2 className="title-text mb-0">Certified Carbon Offsets</h2>
                <BrandSlider />
              </TitleSection>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm="10" lg="10" md="10">
              <TitleSection spacer="mt-5 mb-5" textAlign="text-center">
                <h2 className="title-text mb-60">Carbon Offset Utilization In Other Industries</h2>
                <p className="paragraph-text mb-0 p-5">
                  <img src="/assets/images/home/Logos.svg"/> 
                </p>
              </TitleSection>
            </Col>
          </Row>
          <FeaturesNav />
          <ServiceSliderOne sectionSpace="sec-ptb-160 pt-0" />
          <section
            id="extra-features-section"
            className="extra-features-section sec-ptb-160 bg-light-gray clearfix mb-5"
            style={{backgroundColor: '#F7FCF8'}}
          >
            <Container className="px-5">
              <Row>
                <Col xs={12} md={6} className="p-5">
                  <h3>VacayGreen for the Property Manager</h3>
                  <p className="mt-4">
                  VacayGreen provides Property Managers with the resources to manage environmentally friendly vacation rentals. Our industry specific knowledge and relationships have been used to craft a first of its kind solution for carbon neutral vacations down to the individual property and reservation. Contact us to learn more regarding the benefits of becoming a carbon neutral property manager. </p> 
                  <p>We will provide a complete portfolio analysis free of charge.
                  </p>
                  <p>
                    <a className="btn btn-primary" style={{backgroundColor:'#6c9807', borderColor:'#6c9807'}} href="/#contact-section">Contact Us</a>
                  </p>
                </Col>
                <Col xs={12} md={6} className="text-center p-5">
                  <img src="/assets/images/home/pm-1.svg" className="mb-5"/>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} className="text-center">
                  <img src="/assets/images/home/certificate-1.svg" className="mb-5"/>
                </Col>
                <Col xs={12} md={6} className="p-5">
                  <h3 className="mt-5">VacayGreen Guest Experience</h3>
                  <p className="mt-4">
                  Each guest receives a certificate of their stay:
                  <ul>
                  <li> Carbon offset project specific information </li>
                  <li> Offset equivalencies to visualize environmental benefit  </li>
                  <li> Social media badge  </li>
                  </ul>
                    
                  </p>
                  <p>
                    <a className="btn btn-primary" style={{backgroundColor:'#6c9807', borderColor:'#6c9807'}} href="/#contact-section">Contact Us</a>
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
          <FaqSectionOne />
        </main>
      </LayoutOne>
    </Fragment>
  );
};

export default HomePageOne;
