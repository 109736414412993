
export async function getEmissionsEstimate(zipCode, propertyCount) {
    const urlBase = process.env.REACT_APP_API_BASE || "http://localhost:8080";
    const url = urlBase + "/api/v1/calculator/estimate?zipcode=" + zipCode + "&propertycount=" + propertyCount;
    try{
        const response = await fetch(url);
        if(response.status === 200) {
            const result = await response.json();
            return { result: result, hasError: false };
        }
        else if(response.status === 400) {
            const error = await response.text();
            return { result: null, hasError: true };
        }
    }catch(e){
        return { result: null, hasError: true };;
    }
}